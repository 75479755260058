import React, {Component} from 'react'
import '../style/login.css';
import {connect} from "react-redux";
import {login, getPassword, getEmail, getLoginUserDetail} from "../actions/loginActions"
import {auth, db} from "../Firebase";
import ReactNotification, {store} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import {Modal} from '@material-ui/core'
import close from "../assests/close.png";

var D = undefined;

class Login extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.EmailChange = this.EmailChange.bind(this);
        this.PasswordChange = this.PasswordChange.bind(this);
        this.SubmitData = this.SubmitData.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleForgetPassword = this.handleForgetPassword.bind(this);
        this.ForgotEmailChange = this.ForgotEmailChange.bind(this);
        this.getUserData = this.getUserData.bind(this);
        console.log(D);
    }

    state = {
        value: "",
        src: null,
        crop: {
            x: 10,
            y: 10,
            width: 80,
            height: 80,
        },
        verifyUser: null,
        open: false
    };

    handleForgetPassword() {
        if (this.props.email !== '') {
            auth
                .auth()
                .sendPasswordResetEmail(this.props.email)
                .then((response) => {
                    console.log(response);
                    if (response === undefined) {
                        store.addNotification({
                            title: "Proof Readin",
                            message: "Email Send Successfully Check Your Email",
                            type: "warning",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            }
                        });
                        this.setState({open: false});
                    }
                })
                .catch((error) => {
                    console.log(error.code);
                    store.addNotification({
                        title: "Proof Reading",
                        message: "Invalid Email Address",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 1000,
                            onScreen: true
                        }
                    });
                })
        }

    }


    handleChange() {
        this.props.history.push('/SignUp');
        // this.setState({value: event.target.value})
    }

    EmailChange(event) {
        console.log(event.target.value);
        this.props.getEmail(event.target.value);
    }

    ForgotEmailChange(event) {
        console.log(event.target.value);
        this.props.getEmail(event.target.value);
    }

    PasswordChange(event) {
        console.log(event.target.value);
        this.props.getPassword(event.target.value);
    }

    SubmitData() {
        console.log(
            this.props.email + "/" + this.props.password
        )
    }

    handleLogin(navigate) {
        auth
            .auth()
            .signInWithEmailAndPassword(this.props.email, this.props.password)
            .then((response) => {
                console.log(response);
                console.log(response.user.uid);
                if (response.user.uid !== undefined) {
                    auth
                        .auth()
                        .onAuthStateChanged(function (user) {
                            console.log(user.emailVerified);
                            D = user.emailVerified;
                            if (user.emailVerified === false) {
                                auth
                                    .auth()
                                    .currentUser
                                    .sendEmailVerification()
                                    .catch((err) => {
                                        console.log(err.code);
                                    })
                            } else {
                                console.log(D);
                                D = true;
                                store.addNotification({
                                    title: "Proof Reading",
                                    message: "Login Success",
                                    type: "warning",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                        duration: 1000,
                                        onScreen: true
                                    }
                                });
                                navigate.push('/Home');
                            }
                        });
                    this.getUserData(response.user.uid);
                    localStorage.setItem('UID', response.user.uid);
                    // setTimeout(() => {
                    //     this.props.history.push('/Home');
                    // }, 1500);
                }
            })
            .catch((error) => {
                console.log(error.response);
                console.log(error.code);
                switch (error.code) {
                    case "auth/invalid-email":
                        //return this.createNotification('error');
                        // return alert('Invalid Email');
                        return store.addNotification({
                            title: "Proof Reading",
                            message: "Invalid Email Address",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            }
                        });
                    case "auth/wrong-password":
                        //return this.createNotification('error');
                        return store.addNotification({
                            title: "Proof Reading",
                            message: "Invalid Password",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            }
                        });
                    case "auth/user-not-found":
                        //return this.createNotification('error');
                        return store.addNotification({
                            title: "Proof Reading",
                            message: "Invalid Email Address",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 1000,
                                onScreen: true
                            }
                        });
                }
            })
    }

    getUserData(uid) {
        db
            .collection('users')
            .doc(uid)
            .get()
            .then((snapshot) => {
                const data = snapshot.data();
                localStorage.setItem('userData', JSON.stringify(snapshot.data()));
                console.log(snapshot.data());
                this.props.getLoginUserDetail(snapshot.data());
            })
            .catch((error) => {
                console.log(error);
            })
    }

    handleOpen = () => {
        this.setState({open: true})
    };

    handleClose = () => {
        this.setState({open: false})
    };

    render() {
        return (
            <div id="mainDivHome">
                <ReactNotification/>
                <div className="loginPage col-lg-12 col-md-12 sol-sm-12 h-50">
                    <div className="col-lg-12 col-sm-12">
                        <label className="headTital2">Proofreading By A UK PhD <br/> CoP Platform</label>
                    </div>
                    <div id="subDiv" className="position-relative col-lg-12 col-sm-12 mt-4">
                        <div className="text-center position-absolute align-items-center justify-content-center"
                             id="absoluteDiv">
                            <div className="headTitalView">
                                <text className="headTital mb-2">STAFF LOGIN</text>
                            </div>
                            <div className="col ">
                                <div className="form-group">
                                    <label className="emailTxt" htmlFor="email">Email address</label>
                                    <input className="form-control inputsLogin" type="email" id="email"
                                           onChange={this.EmailChange}/>
                                </div>

                                <div className="form-group mt-2">
                                    <label className="emailTxt" htmlFor="password">Password</label>
                                    <input className="form-control inputsLogin" type="password" id="password"
                                           onChange={this.PasswordChange}/>
                                </div>

                                <div className="w-100 text-right mb-3" onClick={this.handleForgetPassword}>
                                    <p className="emailTxt forget-button" onClick={this.handleOpen}>Forgot
                                        password?</p>
                                </div>

                                <div className="text-center mt-5">
                                    <button id="loginButton" className="btn emailTxt" onClick={() => {
                                        this.handleLogin(this.props.history);
                                        // setTimeout(() => {
                                        //     console.log('DDDDDDDDDD ' + D);
                                        //     if (D === true) {
                                        //         setTimeout(()=>{
                                        //             this.props.history.push('/Home');
                                        //         },1500);
                                        //     } else {
                                        //         if (D !== undefined) {
                                        //             store.addNotification({
                                        //                 title: "Proof Reading",
                                        //                 message: "Email Not Verified! Try Again",
                                        //                 type: "danger",
                                        //                 insert: "top",
                                        //                 container: "top-right",
                                        //                 animationIn: ["animate__animated", "animate__fadeIn"],
                                        //                 animationOut: ["animate__animated", "animate__fadeOut"],
                                        //                 dismiss: {
                                        //                     duration: 1000,
                                        //                     onScreen: true
                                        //                 }
                                        //             });
                                        //         }
                                        //     }
                                        // }, 1500);
                                    }}>Login
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/*   <div className="text-center mt-2 mainLogin">
                        <button id="loginButton" className="btn emailTxt" onClick={() => {
                            this.handleLogin();
                            setTimeout(() => {
                                console.log('DDDDDDDDDD ' + D);
                                if (D === true) {
                                    setTimeout(() => {
                                        this.props.history.push('/Home');
                                    }, 1500);
                                } else {
                                    if (D !== undefined) {
                                        store.addNotification({
                                            title: "Proof Reading",
                                            message: "Email Not Verified! Try Again",
                                            type: "danger",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animate__animated", "animate__fadeIn"],
                                            animationOut: ["animate__animated", "animate__fadeOut"],
                                            dismiss: {
                                                duration: 1000,
                                                onScreen: true
                                            }
                                        });
                                    }
                                }
                            }, 1500);
                        }}>Login
                        </button>
                    </div>*/}
                </div>

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: 'none',
                        borderColor: "transparent"
                    }}
                >
                    <div
                        className="bg-white  align-items-center modalStyle pt-2 pb-3 d-flex flex-column">
                        <div className="w-100 mb-1 d-flex justify-content-end">
                            <img src={close} alt="Logo" className="font-weight-bold closeImgLogin mr-2"
                                 id='CloseModal' onClick={this.handleClose}/>
                        </div>


                        <div
                            className="form-group align-items-center justify-content-center text-center setWidthLogin">
                            <label htmlFor="fname" className="emailTxt">Email</label>
                            <div className="align-items-center">
                                <input type="email" id="cname" className="form-control inputsLogin"
                                       onChange={this.ForgotEmailChange}/>
                            </div>
                        </div>

                        <div className="text-center mt-2">
                            <button id="loginButton" className="btn emailTxt"
                                    onClick={this.handleForgetPassword}>Confirm
                            </button>
                        </div>

                    </div>

                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loginData: state.Login.loginData,
        email: state.Login.email,
        password: state.Login.password,
    }
};

export default connect(
    mapStateToProps,
    {
        login,
        getEmail,
        getPassword,
        getLoginUserDetail
    },
)(Login);
