const INITIAL_STATE = {
    clientName: '',
    clientLink: '',
    clientInvoiceNumber: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOAD_CLIENT_NAME':
            return { ...state, clientName: action.payload };
        case 'LOAD_CLIENT_GENERATE_LINK':
            return {...state, clientLink: action.payload};
        case 'LOAD_CLIENT_INVOICE_NUMBER':
            return {...state, clientInvoiceNumber: action.payload};
        default:
            return state;
    }
};
