import {db} from '../Firebase';

export const getTemplateData = (uid) => async dispatch => {
    await db
        .collection('template')
        .doc(uid)
        .get()
        .then((snapshot)=>{
            // console.log(snapshot.data());
            if (snapshot.id === uid){
                dispatch({
                    type: 'GET_D_NAME',
                    payload: snapshot.data().name,
                });
                dispatch({
                    type: 'GET_AND_SET_POST',
                    payload: snapshot.data().post,
                });
                dispatch({
                    type: 'GET_AND_SET_COMPANY',
                    payload: snapshot.data().company,
                });
                dispatch({
                    type: 'GET_AND_SET_REGISTRATION_NUMBER',
                    payload: snapshot.data().registorNumber,
                });
                dispatch({
                    type: 'GET_AND_SET_EMAILADDRESS',
                    payload: snapshot.data().emailAddress,
                });
                dispatch({
                    type: 'GET_AND_SET_MOBILE',
                    payload: snapshot.data().mobile,
                });
                dispatch({
                    type: 'GET_AND_SET_HEAD_LINE',
                    payload: snapshot.data().headLine,
                });
                dispatch({
                    type: 'GET_AND_SET_DESCRIPTION',
                    payload: snapshot.data().description,
                });
                dispatch({
                    type: 'GET_AND_SET_ISSUED_DATE',
                    payload: snapshot.data().issuessDate,
                });
                dispatch({
                    type: 'GET_AND_SET_DESCRIPTION_TWO',
                    payload: snapshot.data().descriptionTwo,
                });
                // console.log(snapshot.data().companyLogo);
                dispatch({
                    type: 'GET_AND_SET_COMPANY_LOGO',
                    payload: snapshot.data().companyLogo,
                });
                dispatch({
                    type: 'GET_AND_SET_SIGNATURE_IMAGE',
                    payload: snapshot.data().signatureImage,
                });
                dispatch({
                    type: 'GET_AND_SET_COMPANY_SEAL',
                    payload: snapshot.data().companySeal,
                });
                dispatch({
                    type: 'GET_AND_SET_LOGO_TWO',
                    payload: snapshot.data().logoTwo,
                });
                dispatch({
                    type: 'GET_AND_SET_TEMPLATE_DATA',
                    payload: snapshot.data().id,
                });
                dispatch({
                    type: 'GET_AND_SET_FOOTER_DESCRIPTION_ONE',
                    payload: snapshot.data().footerDescriptionOne,
                });
                dispatch({
                    type: 'GET_AND_SET_FOOTER_DESCRIPTION_TWO',
                    payload: snapshot.data().footerDescriptionTwo,
                });
                dispatch({
                    type: 'GET_AND_SET_FOOTER_DESCRIPTION_THREE',
                    payload: snapshot.data().footerDescriptionThree,
                });
                dispatch({
                    type: 'GET_AND_SET_FOOTER_DESCRIPTION_FORE',
                    payload: snapshot.data().footerDescriptionFore,
                });
                dispatch({
                    type: 'GET_AND_SET_FOOTER_DESCRIPTION_FIVE',
                    payload: snapshot.data().footerDescriptionFive,
                });
            }
        })
        .catch((error)=>{
            console.log(error.code);
        })
};
