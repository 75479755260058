import logo from '../assests/logo.png';
import close from '../assests/close.png';
import React, {Component} from 'react'
import '../style/signupCss.css';
import {connect} from "react-redux";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {
    changeFirstName,
    changeLastName,
    changePhoneNumber,
    changeEmail,
    changePassword,
    changeImageUrl,
    getUserId,
    handleFirstName,
    handleLastName,
    handleEmail,
    handlePhoneNumber,
    pickImgName,
    pickImgPath,
    getConfirmPassword,
    getNewPassword,
    changeCMPassword
} from "../actions/SignUpActions";
import {
    getLoginUserDetail
} from "../actions/loginActions";
import {auth, db, fireStorage} from '../Firebase';
import ReactNotification, { store } from 'react-notifications-component';
import {Avatar} from "@material-ui/core";

var user;
var convertuserData;

class SignUp extends Component {

    constructor(props) {
        super(props);
        this.getLocalStorageVal();
        this.backNavigate = this.backNavigate.bind(this);
        this.FirstNameChange = this.FirstNameChange.bind(this);
        this.LastNameChange = this.LastNameChange.bind(this);
        this.ContactNumberChange = this.ContactNumberChange.bind(this);
        this.EmailChange = this.EmailChange.bind(this);
        this.PasswordChange = this.PasswordChange.bind(this);
        this.CmPasswordChange = this.CmPasswordChange.bind(this);
        this.ImageUrlChange = this.ImageUrlChange.bind(this);
        this.handleSignUp = this.handleSignUp.bind(this);
        this.createNewUser = this.createNewUser.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.ChangeFirstName = this.ChangeFirstName.bind(this);
        this.ChangeLastName = this.ChangeLastName.bind(this);
        this.ChangeEmail = this.ChangeEmail.bind(this);
        this.ChangeContactNumber = this.ChangeContactNumber.bind(this);
        this.updateProfileData = this.updateProfileData.bind(this);
        this.getLocalStorageVal = this.getLocalStorageVal.bind(this);
        this.getProfileData = this.getProfileData.bind(this);
        this.ChangeNewPassword = this.ChangeNewPassword.bind(this);
        this.ChangeConfirmPassword = this.ChangeConfirmPassword.bind(this);
        //this.uploadImage = this.uploadImage.bind(this);
    }

    state = {
        value: "",
        src: null,
        crop: {
            unit: '%',
            aspect: 16 / 9,
            x: 10,
            y: 10,
            width: 50,
            height: 50
        },
        croppedImageUrl: null,
        cropComplete: {}
    };

    componentDidMount() {
        const user = localStorage.getItem("UID");
        console.log(user);
        const userVal = localStorage.getItem("userData");
        if (userVal !== null) {
            console.log(JSON.parse(userVal));
            convertuserData = JSON.parse(userVal);
        }
        if (user === null) {
            this.props.history.push("/Login");
        }
    }

    getLocalStorageVal() {
        user = localStorage.getItem("UID");
        console.log(user);
        this.getProfileData(user);
    }

    updatePassword(password) {
        if (this.props.newPassword !== '' && this.props.confirmPassword !== '') {
            if (this.props.newPassword === this.props.confirmPassword) {
                auth
                    .auth()
                    .currentUser
                    .updatePassword(password)
                    .then((response) => {
                        console.log(response);
                        console.log('Successfully update password');
                        if (response === undefined) {
                            store.addNotification({
                                title: "Proof Reading",
                                message: "Password Updated Successfully",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 1000,
                                    onScreen: true
                                }
                            });
                        }
                        if (response === undefined) {
                            setTimeout(() => {
                                this.props.history.push("/Login");
                            }, 2000);
                        }
                        // auth
                        //     .auth()
                        //     .signOut()
                        //     .then((res)=>{
                        //         console.log(res);
                        //         if (res === undefined){
                        //             setTimeout(()=>{
                        //                 this.props.history.push("/Login");
                        //             },2000);
                        //         }
                        //     })
                        //     .catch((err)=>{
                        //         console.log(err.code);
                        //     })
                    })
                    .catch((error) => {
                        console.log('Error fetching user data:', error);
                        console.log(error.code);
                    });
            } else {
                store.addNotification({
                    title: "Proof Reading",
                    message: "Check Your Password",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1500,
                        onScreen: true
                    }
                });
            }
        }
    }

    // uploadImage(){
    //     const uploadTask = fireStorage.ref(`/images/${this.props.imgName}`).put(this.props.imgPath);
    //     //initiates the firebase side uploading
    //     uploadTask.on('state_changed',
    //         (snapShot) => {
    //             console.log(snapShot)
    //         }, (err) => {
    //             console.log(err)
    //         }, () => {
    //             fireStorage.ref('images').child(this.props.imgName).getDownloadURL()
    //                 .then(fireBaseUrl => {
    //                     console.log(fireBaseUrl);
    //                     this.props.changeImageUrl(fireBaseUrl);
    //                 })
    //         })
    // }

    backNavigate() {
        this.props.history.push("/");
    }

    FirstNameChange(event) {
        console.log(event.target.value);
        this.props.changeFirstName(event.target.value);
    }

    LastNameChange(event) {
        console.log(event.target.value);
        this.props.changeLastName(event.target.value);
    }

    ContactNumberChange(event) {
        console.log(event.target.value);
        this.props.changePhoneNumber(event.target.value);
    }

    EmailChange(event) {
        console.log(event.target.value);
        this.props.changeEmail(event.target.value);
    }

    PasswordChange(event) {
        console.log(event.target.value);
        this.props.changePassword(event.target.value);
    }

    CmPasswordChange(event) {
        console.log(event.target.value);
        this.props.changeCMPassword(event.target.value);
    }

    ImageUrlChange(event) {
        console.log(event.target.value);
    }

    ChangeFirstName(event) {
        console.log(event.target.value);
        this.props.handleFirstName(event.target.value);
    }

    ChangeLastName(event) {
        console.log(event.target.value);
        this.props.handleLastName(event.target.value);
    }

    ChangeEmail(event) {
        this.props.handleEmail(event.target.value);
    }

    ChangeContactNumber(event) {
        console.log(event.target.value);
        this.props.handlePhoneNumber(event.target.value);
    }

    ChangeNewPassword(event) {
        console.log(event.target.value);
        this.props.getNewPassword(event.target.value);
    }

    ChangeConfirmPassword(event) {
        console.log(event.target.value);
        this.props.getConfirmPassword(event.target.value);
    }

    getProfileData(uid) {
        db
            .collection('users')
            .doc(uid)
            .get()
            .then((snapshot) => {
                console.log(snapshot.data());
                localStorage.setItem('userData', JSON.stringify(snapshot.data()));
                this.props.getLoginUserDetail(snapshot.data());
            })
            .catch((error) => {
                console.log(error);
            })
    }

    handleSignUp() {
        console.log(this.props.email);
        auth
            .auth()
            .createUserWithEmailAndPassword(this.props.email, this.props.password)
            .then((response) => {
                console.log(response.user.uid);
                if (response.user.uid !== undefined) {
                    this.props.getUserId(response.user.uid);
                    setTimeout(() => {
                        this.createNewUser(response.user.uid);
                    }, 1000);
                }
            })
            .catch((error) => {
                console.log(error.response);
                console.log(error.code);
                switch (error.code) {
                    case "auth/email-already-in-use":
                        //return alert('user already exist');
                        return store.addNotification({
                            title: "Proof Reading",
                            message: "User Already Exist",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 1500,
                                onScreen: true
                            }
                        });
                    case "auth/invalid-email":
                        return store.addNotification({
                            title: "Proof Reading",
                            message: "Invalid Email",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 1500,
                                onScreen: true
                            }
                        });
                    //return alert('Invalid Email');
                }
            })
    }

    createNewUser(uid) {
        db.collection('users')
            .doc(uid)
            .set({
                firstname: this.props.firstName,
                lastname: this.props.lastName,
                password: this.props.password,
                email: this.props.email,
                contactnumber: this.props.contactNumber,
                image: null,
                id: uid,
            })
            .then((response) => {
                store.addNotification({
                    title: "Proof Reading",
                    message: "User Added Successfully Please Check Your Email To Verify Your Account",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1500,
                        onScreen: true
                    }
                });
                this.props.changeFirstName('');
                this.props.changeLastName('');
                this.props.changePhoneNumber(null);
                this.props.changeEmail('');
                this.props.changePassword('');
                this.props.changeCMPassword('');

                auth
                    .auth()
                    .currentUser
                    .sendEmailVerification()
                    .then((result) => {
                        console.log(result);
                    })
                    .catch((error) => {
                        console.log(error.code);
                    });
            })
            .catch((error) => {
                console.log(error.code);
            })
    }

    updateProfileData() {
        const uid = user;
        const data = {
            firstname: this.props.fName,
            lastname: this.props.lName,
            image: this.props.imgUrl,
            //password: this.props.password,
            email: this.props.emailAddress,
            contactnumber: this.props.phoneNumber,
            id: uid,
        };
        db.collection('users').doc(uid).update(data)
            .then((res) => {
                console.log(res);
                if (res === undefined) {
                    localStorage.setItem('userData', JSON.stringify(data));
                    store.addNotification({
                        title: "Proof Reading",
                        message: "Profile Updated Successfully",
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 1500,
                            onScreen: true
                        }
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () =>
                    this.setState({
                        src: reader.result,
                    }),
                false
            );
            reader.readAsDataURL(e.target.files[0]);
            console.log(e.target.files[0]);
            const fileName = e.target.files[0].name;
            const file = e.target.files[0];
            // this.props.pickImgName(e.target.files[0].name);
            // this.props.pickImgPath(e.target.files[0]);
            const uploadTask = fireStorage.ref(`/images/${fileName}`).put(file);
            //initiates the firebase side uploading
            uploadTask.on('state_changed',
                (snapShot) => {
                    console.log(snapShot)
                }, (err) => {
                    console.log(err)
                }, () => {
                    fireStorage.ref('images').child(fileName).getDownloadURL()
                        .then(fireBaseUrl => {
                            console.log(fireBaseUrl);
                            this.props.changeImageUrl(fireBaseUrl);
                        })
                })
        }
    };


    onImageLoaded = image => {
        // console.log('onCropComplete', image)
        this.imageRef = image;
    };

    onCropComplete = crop => {
        console.log('onCropComplete', crop);
        this.setState({cropComplete: crop})

    };

    onFinsih = () => {
        this.makeClientCrop(this.state.cropComplete);
        this.setState({src: null})
    };

    onCropChange = crop => {
        this.setState({crop})
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    render() {
        return (
            <div className="d-flex justify-content-center align-items-center mainDiv">
                <ReactNotification/>
                    <p className="position-absolute m-auto backBtnTxt" id="backPara" onClick={this.backNavigate}>Back</p>

                    <img src={close} alt="Logo" className="position-absolute m-auto font-weight-bold closeImg"
                         id="ClosePara" onClick={this.backNavigate}/>

                <div className="container mt-4">

                    <div className="row align-items-center">

                        <div className="col-xl-6 col-lg-12 px-5" id="firstCol">

                            <div className="d-inline-flex align-items-center pl-3" id="setBlock">
                                <input id="file-input" type="file" onChange={this.onSelectFile}/>
                                <label htmlFor="file-input">
                                    {this.props.imgUrl === '' ? (
                                        <Avatar
                                            src={this.state.src === null ? this.state.croppedImageUrl === null ? logo : this.state.croppedImageUrl : this.state.croppedImageUrl}
                                            alt="Logo"
                                            id="profileImg"
                                            //className="proImgStyles"
                                            style={{
                                                height: '110px', width: '110px', 'border': '1px solid currentColor',
                                                'border-color': 'white',
                                            }}
                                        />
                                    ) : (
                                        <Avatar
                                            src={this.props.imgUrl}
                                            alt="Logo"
                                            id="profileImg"
                                            //className="proImgStyles"
                                            style={{
                                                height: '110px', width: '110px', 'border': '1px solid currentColor',
                                                'border-color': 'white'
                                            }}
                                        />
                                    )}
                                </label>

                                <p id="profileName"
                                   className="pl-4 headTxt">{this.props.userData.firstname}{' '}{this.props.userData.lastname}</p>
                            </div>

                            <div className="mt-4">

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                    <label htmlFor="fname" className="labelTxt">First Name</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth">
                                        <input type="text" id="email" className="form-control inputs txtInputVal"
                                               value={this.props.fName} onChange={this.ChangeFirstName}/>
                                        <p className="m-0 font-weight-bold editTxt">Edit</p>
                                    </div>
                                </div>

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                    <label htmlFor="lname" className="labelTxt">Last Name</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth">
                                        <input type="text" id="email" className="form-control inputs txtInputVal"
                                               value={this.props.lName} onChange={this.ChangeLastName}/>
                                        <p className="m-0 font-weight-bold editTxt">Edit</p>
                                    </div>
                                </div>

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                    <label htmlFor="mobile" className="labelTxt">Phone Number</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth">
                                        <input type="text" id="mobile" className="form-control inputs txtInputVal"
                                               value={this.props.phoneNumber} onChange={this.ChangeContactNumber}/>
                                        <p className="m-0 font-weight-bold editTxt">Edit</p>
                                    </div>
                                </div>

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                    <label htmlFor="email" className="labelTxt">Email Address</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth">
                                        <input type="email" id="email" className="form-control inputs txtInputVal"
                                               value={this.props.emailAddress} onChange={this.ChangeEmail}
                                               disabled={true}/>
                                        {/*<p className="m-0 font-weight-bold editTxt">Edit</p>*/}
                                    </div>
                                </div>

                                <div className="mb-3 w-100 text-center setRight">
                                    <button id="updateBtn" className="btn btnTxt"
                                            onClick={() => {
                                                this.updateProfileData();
                                                setTimeout(() => {
                                                    this.updateProfileData();
                                                }, 1000);
                                            }}>UPDATE
                                    </button>
                                </div>

                                {/*<hr className="line"/>*/}

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                    <label htmlFor="password" className="labelTxt">Password</label>
                                    <div
                                        className="d-inline-flex align-items-center justify-content-between setWidth">
                                        <input id="password" className="form-control inputs txtInputVal" type="password"
                                               value={this.props.password}/>
                                        <p className="m-0 font-weight-bold"
                                           style={{"font-size": "13px", visibility: "hidden"}}>Edit</p>
                                    </div>
                                </div>

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                    <label htmlFor="newPassword" className="labelTxt">New Password</label>
                                    <div
                                        className="d-inline-flex align-items-center justify-content-between setWidth">
                                        <input type="password" id="newPassword"
                                               className="form-control inputs txtInputVal"
                                               onChange={this.ChangeNewPassword}/>
                                        <p className="m-0 font-weight-bold"
                                           style={{"font-size": "13px", visibility: "hidden"}}>Edit</p>
                                    </div>
                                </div>

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                    <label htmlFor="confirmPassword" className="labelTxt">Confirm Password</label>
                                    <div
                                        className="d-inline-flex align-items-center justify-content-between setWidth">
                                        <input type="password" id="confirmPassword"
                                               className="form-control inputs txtInputVal"
                                               onChange={this.ChangeConfirmPassword}/>
                                        <p className="m-0 font-weight-bold"
                                           style={{"font-size": "13px", visibility: "hidden"}}>Edit</p>
                                    </div>
                                </div>

                                <div className="pb-4 w-100 text-center setRight">
                                    <button id="updateBtn" className="btn btnTxt"
                                            onClick={() => {
                                                this.updatePassword(this.props.confirmPassword);
                                            }}>UPDATE
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div className="col-xl-6 col-lg-12 px-5">

                            <div
                                className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                <label htmlFor="fname1" className="labelTxt">First Name</label>
                                <div className="d-inline-flex align-items-center justify-content-between setWidth">
                                    <input type="text" id="emailAddress2" className="form-control inputs txtInputVal"
                                           onChange={this.FirstNameChange}/>
                                    <p className="m-0 font-weight-bold"
                                       style={{"font-size": "13px", visibility: "hidden"}}>Edit</p>
                                </div>
                            </div>

                            <div
                                className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                <label htmlFor="lname1" className="labelTxt">Last Name</label>
                                <div className="d-inline-flex align-items-center justify-content-between setWidth">
                                    <input type="text" id="emailAddress2" className="form-control inputs txtInputVal"
                                           onChange={this.LastNameChange}/>
                                    <p className="m-0 font-weight-bold"
                                       style={{"font-size": "13px", visibility: "hidden"}}>Edit</p>
                                </div>
                            </div>

                            <div
                                className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                <label htmlFor="emailAddress2" className="labelTxt">Email Address</label>
                                <div className="d-inline-flex align-items-center justify-content-between setWidth">
                                    <input type="email" id="emailAddress2" className="form-control inputs txtInputVal"
                                           onChange={this.EmailChange}/>
                                    <p className="m-0 font-weight-bold"
                                       style={{"font-size": "13px", visibility: "hidden"}}>Edit</p>
                                </div>
                            </div>

                            <div
                                className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                <label htmlFor="phoneNumber" className="labelTxt">Phone Number</label>
                                <div className="d-inline-flex align-items-center justify-content-between setWidth">
                                    <input type="text" id="phoneNumber" className="form-control inputs txtInputVal"
                                           onChange={this.ContactNumberChange}/>
                                    <p className="m-0 font-weight-bold"
                                       style={{"font-size": "13px", visibility: "hidden"}}>Edit</p>
                                </div>
                            </div>

                            <div
                                className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                <label htmlFor="password2" className="labelTxt">Password</label>
                                <div className="d-inline-flex align-items-center justify-content-between setWidth">
                                    <input type="password" id="password2" className="form-control inputs txtInputVal"
                                           onChange={this.PasswordChange}/>
                                    <p className="m-0 font-weight-bold"
                                       style={{"font-size": "13px", visibility: "hidden"}}>Edit</p>
                                </div>
                            </div>

                            <div
                                className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock">
                                <label htmlFor="confirmPassword2" className="labelTxt">Confirm Password</label>
                                <div className="d-inline-flex align-items-center justify-content-between setWidth">
                                    <input type="password" id="confirmPassword2"
                                           className="form-control inputs txtInputVal" onChange={this.CmPasswordChange}/>
                                    <p className="m-0 font-weight-bold"
                                       style={{"font-size": "13px", visibility: "hidden"}}>Edit</p>
                                </div>
                            </div>

                            <div className="mt-4 w-100 text-center setRight">
                                <button className="btn btnTxt" id="createAdminBtn" onClick={this.handleSignUp}>Create
                                    Admin
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        firstName: state.signUp.firstName,
        lastName: state.signUp.lastName,
        email: state.signUp.email,
        password: state.signUp.password,
        contactNumber: state.signUp.contactNumber,
        imgUrl: state.signUp.imgUrl,
        userID: state.signUp.userID,
        userData: state.signUp.userData,
        fName: state.signUp.fName,
        lName: state.signUp.lName,
        emailAddress: state.signUp.emailAddress,
        phoneNumber: state.signUp.phoneNumber,
        imgName: state.signUp.imgName,
        imgPath: state.signUp.imgPath,
        newPassword: state.signUp.newPassword,
        confirmPassword: state.signUp.confirmPassword,
        cmPassword: state.signUp.cmPassword,
    }
};

export default connect(
    mapStateToProps,
    {
        changeFirstName,
        changeLastName,
        changeEmail,
        changePassword,
        changePhoneNumber,
        changeImageUrl,
        getUserId,
        handleFirstName,
        handleLastName,
        handleEmail,
        handlePhoneNumber,
        getLoginUserDetail,
        pickImgName,
        pickImgPath,
        getConfirmPassword,
        getNewPassword,
        changeCMPassword
    },
)(SignUp);
