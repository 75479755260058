import {auth, db, fireStorage} from '../Firebase';
import {store} from 'react-notifications-component';

export const getCustomerName = (text) => async dispatch => {
    dispatch({
        type: 'GET_D_NAME',
        payload: text,
    });
};

export const getCustomerPost = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_POST',
        payload: text,
    });
};

export const getCompanyName = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_COMPANY',
        payload: text,
    });
};

export const getRegNumber = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_REGISTRATION_NUMBER',
        payload: text,
    });
};

export const getCustomerEmailAddress = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_EMAILADDRESS',
        payload: text,
    });
};

export const getCustomerMobileNumber = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_MOBILE',
        payload: text,
    });
};

export const getHeadLine = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_HEAD_LINE',
        payload: text,
    });
};

export const getCompanyLogoImage = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_COMPANY_LOGO',
        payload: text,
    });
};

export const getDescription = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_DESCRIPTION',
        payload: text,
    });
};

export const getIssuesDate = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_ISSUED_DATE',
        payload: text,
    });
};

export const getSignatureImage = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_SIGNATURE_IMAGE',
        payload: text,
    });
};

export const getCompanySeal = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_COMPANY_SEAL',
        payload: text,
    });
};

export const getLogoTwo = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_LOGO_TWO',
        payload: text,
    });
};

export const getDescriptionTwo = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_DESCRIPTION_TWO',
        payload: text,
    });
};

export const getFooterDescriptionOne = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_FOOTER_DESCRIPTION_ONE',
        payload: text,
    });
};

export const getFooterDescriptionTwo = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_FOOTER_DESCRIPTION_TWO',
        payload: text,
    });
};

export const getFooterDescriptionThree = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_FOOTER_DESCRIPTION_THREE',
        payload: text,
    });
};

export const getFooterDescriptionFore = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_FOOTER_DESCRIPTION_FORE',
        payload: text,
    });
};

export const getFooterDescriptionFive = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_FOOTER_DESCRIPTION_FIVE',
        payload: text,
    });
};

export const SaveTemplate = (
    Name,
    Post,
    Company,
    RegNumber,
    Email,
    Mobile,
    HeadLine,
    CompanyLogo,
    Description,
    Date,
    SecondDescription,
    SignatureImg,
    CompanySeal,
    LogoTwo,
    uid,
    DescriptionOne,
    DescriptionTwo,
    DescriptionThree,
    DescriptionFore,
    DescriptionFive
    ) => async dispatch => {
    console.log(uid);
    const data = {
                name: Name,
                post: Post,
                company: Company,
                registorNumber: RegNumber,
                emailAddress: Email,
                mobile: Mobile,
                headLine: HeadLine,
                companyLogo: CompanyLogo,
                description: Description,
                issuessDate: Date,
                descriptionTwo: SecondDescription,
                signatureImage: SignatureImg,
                companySeal: CompanySeal,
                logoTwo: LogoTwo,
                id: uid,
                footerDescriptionOne: DescriptionOne,
                footerDescriptionTwo: DescriptionTwo,
                footerDescriptionThree: DescriptionThree,
                footerDescriptionFore: DescriptionFore,
                footerDescriptionFive: DescriptionFive,
                author: '',
                title: '',
                affiliation: ''
            };
    db.collection('template').doc(uid).update(data)
        .then((res) => {
            console.log(res);
            if (res === undefined) {
                store.addNotification({
                    title: "Proof Reading",
                    message: "Template Updated Successfully",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1500,
                        onScreen: true
                    }
                });
            }
        })
        .catch((error) => {
            console.log(error.code);
        })
};
