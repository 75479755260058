export const changeFirstName = (text) => async dispatch => {
    dispatch({
        type: 'CHANGE_FIRST_NAME',
        payload: text,
    });
};

export const changeLastName = (text) => async dispatch => {
    dispatch({
        type: 'CHANGE_LAST_NAME',
        payload: text,
    });
};

export const changeEmail = (text) => async dispatch => {
    dispatch({
        type: 'CHANGE_EMAIL',
        payload: text,
    });
};

export const changePhoneNumber = (text) => async dispatch => {
    dispatch({
        type: 'CHANGE_CONTACT_NUMBER',
        payload: text,
    });
};

export const changePassword = (text) => async dispatch => {
    dispatch({
        type: 'CHANGE_PASSWORD',
        payload: text,
    });
};

export const changeCMPassword = (text) => async dispatch => {
    dispatch({
        type: 'CM_PASSWORD',
        payload: text,
    });
};

export const changeImageUrl = (text) => async dispatch => {
    dispatch({
        type: 'CHANGE_IMAGE_URL',
        payload: text,
    });
};
export const getUserId = (text) => async dispatch => {
    dispatch({
        type: 'GET_USER_ID',
        payload: text,
    });
};

export const handleFirstName = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_FIRST_NAME',
        payload: text,
    });
};

export const handleLastName = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_LAST_NAME',
        payload: text,
    });
};

export const handleEmail = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_EMAIL_ADDRESS',
        payload: text,
    });
};

export const handlePhoneNumber = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_CONTACT_NUMBER',
        payload: text,
    });
};

export const pickImgName = (text) => async dispatch => {
    dispatch({
        type: 'PICK_IMG_NAME',
        payload: text,
    });
};

export const pickImgPath = (array) => async dispatch => {
    dispatch({
        type: 'PICK_IMG_PATH',
        payload: array,
    });
};

export const getNewPassword = (text) => async dispatch => {
    dispatch({
        type: 'NEW_PASSWORD',
        payload: text,
    });
};

export const getConfirmPassword = (text) => async dispatch => {
    dispatch({
        type: 'CONFIRM_PASSWORD',
        payload: text,
    });
};
