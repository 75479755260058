import logo from '../assests/logo.png';
import React, {Component} from 'react'
import '../style/test2.css';
import {
    getTemplateData
} from '../actions/SettingPageAction';
import {
    changeImageUrl
} from '../actions/SignUpActions';
import {connect} from "react-redux";
import {Avatar} from "@material-ui/core";

class Setting extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSampleOne = this.handleSampleOne.bind(this);
        // this.handleSampleTwo = this.handleSampleTwo.bind(this);
        // this.handleSampleThree = this.handleSampleThree.bind(this);
    }

    state = {
        value: ""
    };

    componentDidMount() {
        const user = localStorage.getItem("UID");
        //console.log(user);
        const userVal = localStorage.getItem("userData");
        if (userVal !== null) {
            //console.log(JSON.parse(userVal));
            const convertData = JSON.parse(userVal);
            const img = convertData.image;
            this.props.changeImageUrl(img);
            //console.log(img);
        }
        //console.log(JSON.parse(userVal));
    }

    handleSampleOne(data, type) {
        //console.log(data);
        this.props.getTemplateData(data);
        setTimeout(() => {
            if (type === type) {
                this.props.history.push("/document")
            }
        }, 1500);
    }

    // handleSampleTwo(data){
    //     console.log(data);
    // }
    //
    // handleSampleThree(data){
    //     console.log(data);
    // }

    handleChange(event) {
        this.setState({value: event.target.value})
    }

    render() {
        return (
            <div className="App1 justify-content-center align-items-center text-center">
                <div className="App-header1">
                    <h1 className="backBtn bTxt" onClick={() => {
                        this.props.history.push("/")
                    }}>Back</h1>
                    {this.props.imgUrl === '' ? (
                        <Avatar
                            src={logo}
                            alt="Logo"
                            className="position-absolute m-auto font-weight-bold"
                            id="ClosePara"
                            style={{height: '50px', width: '50px'}}
                        />
                    ) : (
                        <Avatar
                            src={this.props.imgUrl}
                            alt="Logo"
                            className="position-absolute m-auto font-weight-bold"
                            id="ClosePara"
                            style={{height: '50px', width: '50px'}}
                        />
                    )}
                </div>
                <div className="settingSubHead mb-4">
                    <label className="SettingHeadTxt">
                        CoP Switching Function
                    </label>
                </div>
                <div className="App-body">
                    <button className="btnA btn sBtnTxt" onClick={() => {
                        this.handleSampleOne('Vr2A0nIpXxP9Cm5h8wKb', 'sample 1')
                        // this.handleSampleOne('PLdV3tjspq1AW7jaEJUn', 'sample 1')
                    }}>CoP Type 1
                    </button>
                    <button className="btnA btn sBtnTxt" onClick={() => {
                        this.handleSampleOne('WYEGV5MOqtJwC1P6oI0i', 'sample 2')
                        // this.handleSampleOne('QDZxGoHRnLS7SMKNiZOW', 'sample 2')
                    }}>CoP Type 2
                    </button>
                    <button className="btnA btn sBtnTxt" onClick={() => {
                         this.handleSampleOne('YI0GPtLyxiEChWoP0H7B', 'sample 3')
                        //this.handleSampleOne('QYsHupXzdixB0B6s3lAq', 'sample 3')
                    }}>CoP Type 3
                    </button>
                </div>

                {/*1st view before update*/}

                {/*<div className="App-body">*/}
                {/*    <button className="btnA btn sBtnTxt" onClick={() => {*/}
                {/*        this.handleSampleOne('YI0GPtLyxiEChWoP0H7B', 'sample 1')*/}
                {/*        // this.handleSampleOne('PLdV3tjspq1AW7jaEJUn', 'sample 1')*/}
                {/*    }}>CoP Type 1*/}
                {/*    </button>*/}
                {/*    <button className="btnA btn sBtnTxt" onClick={() => {*/}
                {/*        this.handleSampleOne('Vr2A0nIpXxP9Cm5h8wKb', 'sample 2')*/}
                {/*        // this.handleSampleOne('QDZxGoHRnLS7SMKNiZOW', 'sample 2')*/}
                {/*    }}>CoP Type 2*/}
                {/*    </button>*/}
                {/*    <button className="btnA btn sBtnTxt" onClick={() => {*/}
                {/*        this.handleSampleOne('WYEGV5MOqtJwC1P6oI0i', 'sample 3')*/}
                {/*        //this.handleSampleOne('QYsHupXzdixB0B6s3lAq', 'sample 3')*/}
                {/*    }}>CoP Type 3*/}
                {/*    </button>*/}
                {/*</div>*/}

                {/*<div className="Footer">*/}
                {/*    <button className="FooterBtn btn editBtnTxt" onClick={() => {*/}
                {/*        this.props.history.push("/document")*/}
                {/*    }}>Edit*/}
                {/*    </button>*/}
                {/*</div>*/}

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        templateData: state.setting.templateData,
        imgUrl: state.signUp.imgUrl,
    }
};

export default connect(
    mapStateToProps,
    {
        getTemplateData,
        changeImageUrl,
    },
)(Setting);
