const INITIAL_STATE = {
    customer: [],
    searchTxt: '',
    templates: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ALL_CUSTOMERS':
            // console.log(action.payload);
            return { ...state, customer: action.payload };
        case 'GET_INPUT_SEARCH_TEXT':
            // console.log(action.payload);
            return { ...state, searchTxt: action.payload };
        case 'GET_ALL_TEMPLATES':
            // console.log(action.payload);
            return { ...state, templates: action.payload };
        default:
            return state;
    }
};
