import React, {Component} from 'react'
import '../style/test3.css';
import logo from "../assests/logo_black_orange.png";
import iso from "../assests/logo4.png"
import sfep from "../assests/sfep.png"
import hrdp from "../assests/logo3.png"
import user from "../assests/adminImg.png"
import {PDFDownloadLink, Text, View} from "@react-pdf/renderer";
import MyDoc from "./Template";
import {connect} from "react-redux";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {PDFViewer} from '@react-pdf/renderer';
import Template from "./Template";
import {
    updateUserTable,
    updateUserTableAmendment,
    sendEmail,
} from '../actions/DownloadAction';
import {
    getLogClientID,
    getUserData,
} from '../actions/ClientHomeAction';
import ReactNotification, {store} from "react-notifications-component";
import {Avatar} from "@material-ui/core";

var Data;
var TmpID;
var CustomerName;
var CustomerInvoiceNumber;

class Download extends Component {
    date

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleNewRequest = this.handleNewRequest.bind(this);
        this.handleAmendmentRequest = this.handleAmendmentRequest.bind(this);
        this.custom_close = this.custom_close.bind(this);
        this.handleSendEmail = this.handleSendEmail.bind(this);
        // this.handleDownloadPDF = this.handleDownloadPDF.bind(this);

        var curr = new Date();
        curr.setDate(curr.getDate());
        this.date = curr.toISOString().substr(0, 10);
    }

    state = {
        value: "",
        startDate: Date.now()
    };

    handleChange(event) {
        this.setState({value: event.target.value})
    }

    handleNewRequest(id, val) {
        this.props.updateUserTable(id, val);
    }

    handleAmendmentRequest(id, val) {
        console.log(this.state.startDate);
        if (this.state.startDate !== "") {
            let d = new Date(this.state.startDate);
            let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
            let mo = new Intl.DateTimeFormat('en', {month: 'short'}).format(d);
            let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
            let day = new Intl.DateTimeFormat('en', {weekday: 'short'}).format(d);
            console.log(`${da} ${mo} ${ye}, ${day}`);
            this.setState({
                startDate:  Date.now()
            })
            this.props.updateUserTableAmendment(id, val, `${da} ${mo} ${ye}, ${day}`,TmpID);
        } else {
            store.addNotification({
                title: "Proof Reading",
                message: "Select Date of Certificate Issued",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1500,
                    onScreen: true
                }
            });
        }

    }

    handleSendEmail(name, type, link, invoiceNumber) {
        this.props.sendEmail(name, type, link, invoiceNumber);
    }

    NewLink = () => {
        confirmAlert({
            title: 'Proof Reading',
            message: 'New Link Request Submitted.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        this.handleNewRequest(Data, 'Pending');
                        this.handleSendEmail(CustomerName, 'New Link', 'https://academiahub.co', CustomerInvoiceNumber);
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
        });
    };


    NewAmendment = () => {
        confirmAlert({
            title: 'Proof Reading',
            message: 'Amendment Submitted.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        if (this.props.userVal.amendment === undefined) {
                            store.addNotification({
                                title: "Proof Reading",
                                message: "Amendment Request Successfully Send",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 1500,
                                    onScreen: true
                                }
                            });
                            this.handleAmendmentRequest(Data, 'Pending');
                            window.opener = null;
                            window.open("", "_self");
                            window.close();
                        } else if (this.props.userVal.amendment === 'Pending') {
                            store.addNotification({
                                title: "Proof Reading",
                                message: "Pending Admin Verification",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 1500,
                                    onScreen: true
                                }
                            });
                        } else if (this.props.userVal.amendment === 'accept' || this.props.userVal.amendment === 'finish' || this.props.userVal.amendment === 'reject') {
                            store.addNotification({
                                title: "Proof Reading",
                                message: "Amendment Request Successfully Send",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 1500,
                                    onScreen: true
                                }
                            });
                            this.handleAmendmentRequest(Data, 'Pending');
                            window.opener = null;
                            window.open("", "_self");
                            window.close();
                        }
                        this.handleSendEmail(CustomerName, 'New Amendment', 'https://academiahub.co', CustomerInvoiceNumber);
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
        });
    };

    // handleDownloadPDF(id){
    //     `http://localhost:4000/render?url=http://localhost:3000/PdfScreen/${id}`
    // }

    componentDidMount() {
        document.body.style.backgroundColor = "#FFA300"

        Data = localStorage.getItem('ClientID');
        TmpID = localStorage.getItem('TemplateID');
        //console.log(Data);
        CustomerName = localStorage.getItem('CustomerName');
        CustomerInvoiceNumber = localStorage.getItem('InvoiceNumber');
        // console.log(this.props.clientID);
        // console.log(this.props.titleTxt + '-------' + this.props.author);
        //console.log(this.props.templateVal.id);
    }

    custom_close() {
        window.opener = null;
        window.open('', '_self');
        window.close();
    }

    render() {
        return (
            <div className="container-fluid">
                <ReactNotification/>
                <div className="col-lg-12 col-sm-12">
                    <div className="row">
                        <div className="col-lg-2 col-sm-12 "><img src={logo} alt={"Logo"} className="Headerlogo"/></div>
                        <div className="col-lg-8 col-sm-12 text-center"><label className="titleFont">
                            Welcome to the <br/> World's 1st <br/> Proofreading Certificate Platform
                        </label></div>
                        <div className="col-lg-2 col-sm-12 ">
                            <Avatar
                                src={user}
                                alt="Logo"
                                // onClick={() => {
                                //     this.props.history.push("/PdfScreen/DIz0e8mwYsgvZ2l1M4Az/Vr2A0nIpXxP9Cm5h8wKb")
                                // }}
                                style={{
                                    height: '100px', width: '100px', position: 'relative',
                                    left: 0,
                                    right: 0,
                                    margin: 'auto'
                                }}
                            />
                            <label className="UserLogoFont">
                                Thank you for Choosing Us!
                                <br/>Sincerely,
                                <br/>Dr Thava
                            </label>

                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-2 col-sm-12 text-center">

                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <div className="titleTxtView2">
                                <div className="buttonDiv mb-1 mt-1">
                                    <div className="downloadDateDiv mb-2">
                                        <label> Date of Certificate Issued</label>
                                        <input className="form-control dateSelect mb-4" type="date"
                                               defaultValue={this.date}
                                               onChange={(event) => this.setState({startDate: event.target.value})}
                                        />
                                    </div>
                                    {/*<a href={`http://localhost:4000/render?url=http://localhost:3000/PdfScreen/${Data}/${TmpID}`} download className="btnBDownload" onClick={() => {*/}
                                    {/*    //this.handleAmendmentRequest(this.props.clientID, 'finish')*/}
                                    {/*    console.log('this.props.clientID :' + Data);*/}
                                    {/*    console.log('this.props.templateVal.id :' + TmpID);*/}
                                    {/*    this.handleAmendmentRequest(Data, 'finish')*/}
                                    {/*}}>*/}
                                    {/*    Download*/}
                                    {/*</a>*/}
                                    <a href={`https://us-central1-proof-reading-2021.cloudfunctions.net/readings/render?url=https://academiahub.co/PdfScreen/${Data}/${TmpID}&name=CoP_${this.props.titleTxt.trim()}`}
                                       download className="btn btnB" onClick={() => {

                                        this.handleAmendmentRequest(Data, 'finish')
                                    }}>
                                        Download
                                    </a>
                                    <label className="subViewFont">
                                        Click here to Download. {<label className="subViewFontTwo"> Your PASSWORD is
                                        1234</label>}
                                    </label>
                                </div>
                                <div className="buttonDiv mb-1 mt-1">
                                    <button className="btnB btTxt btn" onClick={() => {
                                        this.NewLink();
                                    }}>Request for New Link
                                    </button>
                                    <label className="subViewFont">
                                        In case if link dosen't work, request here
                                    </label>
                                </div>
                                <div className="buttonDiv mb-1 mt-1">
                                    <button className="btnB btTxt btn" onClick={() => {
                                        this.NewAmendment();
                                    }}>Request Amendment
                                    </button>
                                    <label className="subViewFont">
                                        Change of Certificate information, click here
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 text-center mt-5">
                                <img src={iso} alt={"Logo"} className="Otherlogo"/>
                                <img src={hrdp} alt={"Logo"} className="Otherlogo"/>
                                <img src={sfep} alt={"Logo"} className="Otherlogo"/>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-12">

                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-sm-12 mt-4">
                    <div className="row">
                        <div className="col-lg-2 col-sm-12 text-center">

                        </div>
                        <div className="col-lg-8 col-sm-12 text-center">
                            <div className="titleTxtView2">
                                <label className="footerFont">
                                    Thank you for using our services
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-12">

                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        titleTxt: state.clientReducer.titleTxt,
        author: state.clientReducer.author,
        affiliation: state.clientReducer.affiliation,
        templateVal: state.clientReducer.templateVal,
        clientID: state.clientReducer.clientID,
        userVal: state.clientReducer.userVal,
        clientName: state.downloadReducer.clientName,
        clientLink: state.downloadReducer.clientLink,
        clientInvoiceNumber: state.downloadReducer.clientInvoiceNumber,
    }
};

export default connect(
    mapStateToProps,
    {
        updateUserTable,
        getLogClientID,
        updateUserTableAmendment,
        getUserData,
        sendEmail,
    },
)(Download);

