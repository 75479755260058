const INITIAL_STATE = {
    loginData: '',
    email: '',
    password: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOGIN_DATA':
            // console.log("reducer");
            return { ...state, loginData: action.payload };
        case 'GET_EMAIL':
            return {...state, email: action.payload};
        case 'GET_PASSWORD':
            return {...state, password: action.payload};
        default:
            return state;
    }
};
