import firebase from 'firebase'
import 'firebase/auth';
import  'firebase/firestore'
import 'firebase/storage'

var firebaseConfig = {
    // apiKey: "AIzaSyDjGl_7IKoermdR8xbcm6UAlec6w8IHGnw",
    // authDomain: "testproof-bebe6.firebaseapp.com",
    // projectId: "testproof-bebe6",
    // storageBucket: "testproof-bebe6.appspot.com",
    // messagingSenderId: "1051475713438",
    // appId: "1:1051475713438:web:8e679262396ba71ad28a8f",
    // measurementId: "G-S3P9S914S4"
    // apiKey: "AIzaSyAFY-MCDrLJ1JfltNEG7eWT46mHDmIrkMs",
    // authDomain: "proof-reading-e6b1f.firebaseapp.com",
    // projectId: "proof-reading-e6b1f",
    // storageBucket: "proof-reading-e6b1f.appspot.com",
    // messagingSenderId: "96665261603",
    // appId: "1:96665261603:web:3ff8855521a4369344c396",
    // measurementId: "G-CENLYLMEVV"
    apiKey: "AIzaSyBVcqNqUqWqobQem-G66QuFVbF9eQMZjFk",
    authDomain: "proof-reading-2021.firebaseapp.com",
    projectId: "proof-reading-2021",
    storageBucket: "proof-reading-2021.appspot.com",
    messagingSenderId: "654746197622",
    appId: "1:654746197622:web:51fea96f4055a2491b8957",
    measurementId: "G-H3R3KR8SR2"
};
firebase.initializeApp(firebaseConfig);
export const auth = firebase;
export const db = firebase.firestore();
export const fireStorage = firebase.storage();

export default firebase;
