const INITIAL_STATE = {
    titleTxt: '',
    author: [],
    affiliation: [],
    userVal: {},
    templateVal: {},
    clientID: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_AND_SET_TITLE_TXT':
            return { ...state, titleTxt: action.payload };
        case 'GET_AND_SET_AUTHOR':
            return {...state, author: action.payload};
        case 'GET_AND_AFFILIATION':
            return {...state, affiliation: action.payload};
        case 'GET_USER_DATA_USING_UID':
            return {...state, userVal: action.payload};
        case 'GET_TEMPLATE_DATA_USING_UID':
            return {...state, templateVal: action.payload};
        case 'GET_AND_SET_UNIQ_CLIENT_ID':
            return {...state, clientID: action.payload};
        default:
            return state;
    }
};
