const INITIAL_STATE = {
    D_name: '',
    post: '',
    company: '',
    registration_no: '',
    email_Address: '',
    mobile: '',
    head_line: '',
    companylogo: null,
    description: '',
    date_of_issued: '',
    signature_img: '',
    company_seal: '',
    logo_two: '',
    description_two: '',
    footer_Description_one: '',
    footer_Description_two: '',
    footer_Description_three: '',
    footer_Description_fore: '',
    footer_Description_five: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_D_NAME':
            return { ...state, D_name: action.payload };
        case 'GET_AND_SET_POST':
            return {...state, post: action.payload};
        case 'GET_AND_SET_COMPANY':
            return {...state, company: action.payload};
        case 'GET_AND_SET_REGISTRATION_NUMBER':
            return {...state, registration_no: action.payload};
        case 'GET_AND_SET_EMAILADDRESS':
            return {...state, email_Address: action.payload};
        case 'GET_AND_SET_MOBILE':
            return {...state, mobile: action.payload};
        case 'GET_AND_SET_HEAD_LINE':
            return {...state, head_line: action.payload};
        case 'GET_AND_SET_COMPANY_LOGO':
            // console.log(action.payload);
            return {...state, companylogo: action.payload};
        case 'GET_AND_SET_DESCRIPTION':
            return {...state, description: action.payload};
        case 'GET_AND_SET_ISSUED_DATE':
            return {...state, date_of_issued: action.payload};
        case 'GET_AND_SET_SIGNATURE_IMAGE':
            return {...state, signature_img: action.payload};
        case 'GET_AND_SET_COMPANY_SEAL':
            return {...state, company_seal: action.payload};
        case 'GET_AND_SET_LOGO_TWO':
            return {...state, logo_two: action.payload};
        case 'GET_AND_SET_DESCRIPTION_TWO':
            return {...state, description_two: action.payload};
        case 'GET_AND_SET_FOOTER_DESCRIPTION_ONE':
            return {...state, footer_Description_one: action.payload};
        case 'GET_AND_SET_FOOTER_DESCRIPTION_TWO':
            return {...state, footer_Description_two: action.payload};
        case 'GET_AND_SET_FOOTER_DESCRIPTION_THREE':
            return {...state, footer_Description_three: action.payload};
        case 'GET_AND_SET_FOOTER_DESCRIPTION_FORE':
            return {...state, footer_Description_fore: action.payload};
        case 'GET_AND_SET_FOOTER_DESCRIPTION_FIVE':
            return {...state, footer_Description_five: action.payload};
        default:
            return state;
    }
};
