import React, {Component, useState, useCallback} from 'react'
import '../style/testCss.css';
import logo from "../assests/logo_black_orange.png";
import help from "../assests/help.png";
import pluse from "../assests/PluBtn.png";
import Delete from "../assests/deleteBin.png";
import Sample from "../assests/sampleNew.png";
import {connect} from "react-redux";
import sha256 from 'crypto-js/md5';
import CloseButton from 'react-bootstrap/CloseButton'
import {
    getAffiliation,
    getAuthor,
    getTitleTxt,
    getUserData,
    getTemplateData,
    getLogClientID,
    addedNewData
} from '../actions/ClientHomeAction'
import ReactNotification, {store} from "react-notifications-component";
import {Editor} from '@tinymce/tinymce-react';
import Viewer from 'react-viewer';
import ReactModal from 'react-modal';

var CryptoJS = require("crypto-js");
var ReverseMd5 = require('reverse-md5');

// const formats = ["bold"];
class ClientHome extends Component {

    constructor(props) {
        super(props);
        this.viewReference = this.viewReference.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleChangeAuthor = this.handleChangeAuthor.bind(this);
        this.handleChangeAffiliation = this.handleChangeAffiliation.bind(this);
        this.handleSubmitData = this.handleSubmitData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleEditorChange = this.handleEditorChange.bind(this);
        // this.formats = formats;
        // this.clickBtn = this.clickBtn.bind(this);

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }


    state = {
        value: "",
        values: [],
        Authors: [],
        AfflicationValue: [],
        Afflication: [],
        setVisible: false,
        Visible: false,
        showModal: false
        // setValue:"",
        // editorState: EditorState.createEmpty(),
    };

    handleOpenModal () {
        this.setState({ showModal: true });
    }

    handleCloseModal () {
        this.setState({ showModal: false });
    }

    // =======================================================================================================

    createUI() {
        return this.state.values.map((el, i) =>
            <div key={i} className="container genarateDiv mt-3">
                <Editor
                    apiKey="uqx8f4d97oxysrr7fo1l51fenqedf0gqpf2nry0ylhkxx0ip"
                    // initialValue={}
                    init={{
                        max_chars: 100,
                        height: 150,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image',
                            'charmap print preview anchor help',
                            'searchreplace visualblocks code',
                            'insertdatetime media table paste wordcount'
                        ],
                        toolbar:
                            'bold italic | \
                             superscript subscript'
                    }}
                    onChange={this.handleChanges.bind(this, i)}
                />
                {/*<input type="text" value={el||''} onChange={this.handleChanges.bind(this, i)} />*/}
                <div className="PluseIconDiv  float-right mt-2">
                    <img src={Delete} alt={"Logo"} className="pluseIcon"
                         onClick={this.removeClick.bind(this, i)}/>
                </div>
                {/*<input type='button' value='remove' onClick={this.removeClick.bind(this, i)}/>*/}
            </div>
        )
    }


    handleChanges(i, event) {
        let values = [...this.state.values];
        values[i] = event.target.getContent();
        this.setState({values});
        // console.log(values);
        // this.setState({
        //     Authors: [this.state.values]
        // });
    }

    addClick() {
        this.setState(prevState => ({values: [...prevState.values, '']}))
    }

    removeClick(i) {
        let values = [...this.state.values];
        values.splice(i, 1);
        this.setState({values});
        // console.log(values);
    }

    handleSubmit(event) {
        alert('A name was submitted: ' + this.state.values.join(', '));
        event.preventDefault();
    }

    // =========================================================================================================

    AfflicationUI() {
        return this.state.AfflicationValue.map((el, i) =>
            <div key={i} className="container genarateDiv mt-3">
                <Editor
                    apiKey="uqx8f4d97oxysrr7fo1l51fenqedf0gqpf2nry0ylhkxx0ip"
                    // initialValue={}
                    init={{
                        max_chars: 100,
                        height: 150,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image',
                            'charmap print preview anchor help',
                            'searchreplace visualblocks code',
                            'insertdatetime media table paste wordcount'
                        ],
                        toolbar:
                            'bold italic | \
                             superscript subscript'
                    }}
                    onChange={this.handleChangesAfflication.bind(this, i)}
                />
                {/*<input type="text" value={el||''} onChange={this.handleChanges.bind(this, i)} />*/}
                {/*<input type='button' value='remove' onClick={this.removeClickBtn.bind(this, i)}/>*/}
                <div className="PluseIconDiv  float-right mt-2">
                    <img src={Delete} alt={"Logo"} className="pluseIcon"
                         onClick={this.removeClickBtn.bind(this, i)}/>
                </div>
            </div>
        )
    }


    handleChangesAfflication(i, event) {
        let AfflicationValue = [...this.state.AfflicationValue];
        AfflicationValue[i] = event.target.getContent();
        this.setState({AfflicationValue});
        // console.log(AfflicationValue);
        // this.setState({
        //     Authors: [this.state.values]
        // });
    }

    addClickBtn() {
        this.setState(prevState => ({AfflicationValue: [...prevState.AfflicationValue, '']}))
    }

    removeClickBtn(i) {
        let AfflicationValue = [...this.state.AfflicationValue];
        AfflicationValue.splice(i, 1);
        this.setState({AfflicationValue});
        // console.log(AfflicationValue);
    }

    handleSubmit(event) {
        alert('A name was submitted: ' + this.state.AfflicationValue.join(', '));
        event.preventDefault();
    }

    // =========================================================================================================

    handleChange(event) {
        this.setState({value: event.target.value})
    }

    handleChangeTitle(event) {
        this.props.getTitleTxt(event.target.value);
    }

    handleChangeAuthor(event) {
        this.props.getAuthor(event.target.value);
        // console.log(this.state.editorState);
    }

    handleChangeAffiliation(event) {
        this.props.getAffiliation(event.target.value);
    }

    handleEditorChange = (e) => {
        let a = [];
        // console.log(
        //     e.target.getContent()
        // );
        a.push(e.target.getContent());
        this.setState({
            Authors: a
        });
        // this.props.getAuthor(
        //     a.push(e.target.getContent())
        // );
    };

    handleAfflicationChange = (e) => {
        let b = [];
        // console.log(
        //     e.target.getContent()
        // );
        b.push(e.target.getContent());
        this.setState({
            Afflication: b
        });
        // console.log(b);
        // this.props.getAffiliation(
        //     e.target.getContent()
        // );
    };

    handleSubmitData() {
        this.state.Authors.push(...this.state.values);
        this.props.getAuthor(this.state.Authors);
        // console.log(this.state.Authors);

        this.state.Afflication.push(...this.state.AfflicationValue);
        this.props.getAffiliation(
            this.state.Afflication
        );
        // console.log(this.state.Afflication);

        setTimeout(() => {
            if (this.props.titleTxt === '') {
                store.addNotification({
                    title: "Proof Reading",
                    message: "Something went wrong...",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1500,
                        onScreen: true
                    }
                });
            } else {
                this.props.addedNewData(
                    this.props.clientID,
                    this.props.titleTxt,
                    this.state.Authors,
                    this.state.Afflication,
                    this.props,
                );
            }
        }, 1000);
    }

    // clickBtn(){
    //     // console.log(this.props.author);
    //     let textVal = this.refs.myTextarea;
    //     let cursorStart = textVal.selectionStart;
    //     let cursorEnd = textVal.selectionEnd;
    //     let selectTxt = this.props.author.substring(cursorStart,cursorEnd);
    //     this.props.getAuthor('Hrlllllloooo&sup' + selectTxt);
    //     // let selectTxt2 = document.write("<p>Superscript: " + selectTxt.sup() + "</p>");
    //     console.log(selectTxt);
    // }

    componentDidMount() {
        document.body.style.backgroundColor = "#FFA300"
        // console.log(this.props.history.location);
        // console.log(this.props.match.params);
        // localStorage.setItem('cuid', this.props.match.params.uid);
        // localStorage.setItem('ctid', this.props.match.params.tid);
        // console.log(this.props.match.params.uid);
        // setTimeout(()=>{
        //     this.props.getUserData(this.props.match.params.uid, this.props);
        // },1500);
        // this.props.getTemplateData(this.props.match.params.tid);
        // this.props.getLogClientID(this.props.match.params.uid);
    }

    viewReference() {
        this.setState({
            // setVisible: true,
            // Visible: true,
            showModal: true
        })
    }

    render() {
        return (
            <div className="AppTest ">
                <ReactNotification/>
                <div className="container-fluid p-0">
                    <div className="col-lg-12 col-sm-12">
                        <div className="">
                            <div className="clientHead">
                              <div className="clientHead2">
                                  <img src={logo} alt={"Logo"} className="img-fluid w-100 HeaderlogoView"
                                       style={{'max-width': '150px'}}/>
                                  <div className="hdTxtView">
                                      <div
                                          className="small hdTxt">{this.props.userVal.name === undefined ? undefined : this.props.userVal.name}</div>
                                      <div
                                          className="small hdTxt">{this.props.userVal.invoiceNumber === undefined ? undefined : this.props.userVal.invoiceNumber}</div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="ClientHead">*/}
                    {/*<Viewer*/}
                    {/*    visible={this.state.Visible}*/}
                    {/*    drag={false}*/}
                    {/*    rotatable={false}*/}
                    {/*    defaultScale={1}*/}
                    {/*    onClose={() => {*/}
                    {/*        this.setState({*/}
                    {/*            setVisible: false,*/}
                    {/*            Visible: false*/}
                    {/*        })*/}
                    {/*    }}*/}

                    {/*    images={[{src: Sample, alt: 'Logo'}]}*/}
                    {/*/>*/}

                    <ReactModal

                        isOpen={this.state.showModal}
                        contentLabel="Minimal Modal Example"
                    >
                        <CloseButton aria-label="Hide" onClick={this.handleCloseModal}
                                     className="closeBtn"
                                     data-toggle="tooltip" data-placement="top"
                                     title="Close"
                        />
                        <br/>
                       <div className="mt-4 mb-4">
                           <img className="viewImg" src={Sample} alt="IMAGE" />
                       </div>
                    </ReactModal>
                    {/*    <img src={Sample} alt="Logo" className="sampleLogo" onClick={() => {*/}
                    {/*        this.setState({*/}
                    {/*            setVisible: true,*/}
                    {/*            Visible: true,*/}
                    {/*        })*/}
                    {/*    }}/>*/}
                    {/*    <div className="imageTxtView">*/}
                    {/*        <label className="imageTxt">*/}
                    {/*            Reference Certificate Template*/}
                    {/*        </label>*/}
                    {/*    </div>*/}
                    {/*    <div className="imageTxtView">*/}
                    {/*        <label className="imageTxt2">*/}
                    {/*            Note: Click on the image to zoom in*/}
                    {/*        </label>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-lg-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12"></div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="container mb-5 mt-2">
                                    <h3 className="text-center">Fill up your details here</h3>
                                </div>
                                <div className="container mb-3">
                                    <label htmlFor="txt" className="pl-2 form-label lbTxt2">Title:
                                        <span data-toggle="tooltip" data-placement="top"
                                              title="View Reference Certificate Template"
                                              onClick={this.viewReference}>
                                         <img src={help}  className="help img-fluid w-100"
                                              style={{'max-width': '22px'}}/></span></label>
                                    <input type="text" className="form-control rounded-pill lbTxt" id="txt"
                                           placeholder="Type here" onChange={this.handleChangeTitle}/>
                                    <div className="TitleSubView">
                                        <label className="pl-2 form-label lbTxt2Sub">Please insert the Title of your
                                            Manuscript or Thesis
                                        </label>
                                    </div>
                                </div>
                                <div className="container mb-3 authorDiv">
                                    <label htmlFor="txt" className="pl-2 form-label lbTxt2">Author: <span
                                        data-toggle="tooltip" data-placement="top"
                                        title="View Reference Certificate Template"
                                        onClick={this.viewReference}> <img src={help}  className="help img-fluid w-100"
                                             style={{'max-width': '22px'}}/> </span></label>
                                    <div className="editorDiv">
                                        <Editor
                                            apiKey="uqx8f4d97oxysrr7fo1l51fenqedf0gqpf2nry0ylhkxx0ip"
                                            // initialValue={this.props.author}
                                            init={{
                                                max_chars: 100,
                                                height: 150,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image',
                                                    'charmap print preview anchor help',
                                                    'searchreplace visualblocks code',
                                                    'insertdatetime media table paste wordcount'
                                                ],
                                                toolbar:
                                                    'bold | \
                                                     superscript subscript'
                                            }}
                                            onChange={this.handleEditorChange}
                                        />
                                        {/*<input type='button' value='add more' onClick={this.addClick.bind(this)}/>*/}
                                        {/*<div className="PluseIconDiv float-right mt-2">*/}
                                        {/*    <img src={pluse} alt={"Logo"} className="pluseIcon"*/}
                                        {/*         onClick={this.addClick.bind(this)}/>*/}
                                        {/*</div>*/}
                                    </div>
                                    {/*<textarea className="form-control lbTxt txtAreas" id="author"*/}
                                    {/*          placeholder="type here" onChange={this.handleChangeAuthor}/>*/}
                                    <div className="TitleSubView">
                                        <label className="pl-2 form-label lbTxt2Sub">
                                            Please insert Authors' names here.
                                            <p className="m-0 mt-3">HOW TO INSERT INFORMATION</p>
                                            <p className="m-0 mt-1 ">{<text className="boldFont">Step 1</text>}: {<text className="boldFont">If more than 1 Author, after entering the 1st Author's name,
                                                press
                                                Enter and fill-in the 2nd author's name, and continue for as many
                                                authors as
                                                required</text>}<p/>
                                                <p className="m-0 ">{<text className="boldFont">Step 2</text>}: {<text className="boldFont">If more than 1 Affiliation, then use the superscript function
                                                    to
                                                    insert numbering (Click help icon to view the sample certificate)</text>}</p></p>
                                        </label>
                                    </div>
                                </div>
                                {/*{this.createUI()}*/}
                                <div className="container mb-3 afflicationDiv">
                                    <label htmlFor="txt" className="pl-2 form-label lbTxt2">Affiliation: <span
                                        data-toggle="tooltip" data-placement="top"
                                        title="View Reference Certificate Template"
                                        onClick={this.viewReference}> <img src={help}  className="help img-fluid w-100"
                                             style={{'max-width': '22px'}}/> </span></label>
                                    {/* <textarea className="form-control lbTxt txtAreas" id="txt"
                                            placeholder="type here" onChange={this.handleChangeAffiliation}/>*/}
                                    <div className="editorDiv">
                                        <Editor
                                            apiKey="uqx8f4d97oxysrr7fo1l51fenqedf0gqpf2nry0ylhkxx0ip"
                                            // initialValue={this.props.affiliation}
                                            init={{
                                                max_chars: 100,
                                                height: 150,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image',
                                                    'charmap print preview anchor help',
                                                    'searchreplace visualblocks code',
                                                    'insertdatetime media table paste wordcount'
                                                ],
                                                toolbar:
                                                    'bold | \
                                                     superscript subscript'
                                            }}
                                            onChange={this.handleAfflicationChange}
                                        />
                                        {/*<div className="PluseIconDiv mt-2 float-right">*/}
                                        {/*    <img src={pluse} alt={"Logo"} className="pluseIcon"*/}
                                        {/*         onClick={this.addClickBtn.bind(this)}/>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="TitleSubView">
                                        <label className="pl-2 form-label lbTxt2Sub">Please insert your
                                            Organisation/University details here.
                                            <p className="m-0 mt-3">HOW TO INSERT INFORMATION</p>
                                            <p className="m-0 mt-1">
                                                {<text className="boldFont">If more than 1 Affiliation, label according to
                                                the Superscript
                                                used in Author's column after entering.
                                                    (Click help icon to view the sample certificate)</text>}
                                            </p>
                                        </label>
                                    </div>
                                </div>
                                {/*{this.AfflicationUI()}*/}

                                <div className="container">
                                    <button className="ClientFooterBtn btn mb-5 float-right"
                                            onClick={this.handleSubmitData}>Submit
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        titleTxt: state.clientReducer.titleTxt,
        author: state.clientReducer.author,
        affiliation: state.clientReducer.affiliation,
        userVal: state.clientReducer.userVal,
        templateVal: state.clientReducer.templateVal,
        clientID: state.clientReducer.clientID,
    }
};

export default connect(
    mapStateToProps,
    {
        getTitleTxt,
        getAuthor,
        getAffiliation,
        getUserData,
        getTemplateData,
        getLogClientID,
        addedNewData,
    },
)(ClientHome);
