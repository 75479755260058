import {db, auth} from '../Firebase';
import {store} from "react-notifications-component";

export const getAllCustomers = () => async dispatch => {
    var array = [];
    await db
        .collection('customer')
        .orderBy('createAt')
        .onSnapshot(documentSnapshot => {
            array = [];
            // console.log('User data: ', documentSnapshot);
            documentSnapshot.forEach((doc) => {
                array.push(doc.data());
                // console.log(array);
                // console.log(doc.data().type);
            });
            dispatch({
                type: 'GET_ALL_CUSTOMERS',
                payload: array.reverse()
            })
        });

    return () => db;
};

/*TODO*/
export const deleteCustomer = (id) => async dispatch => {
    await db
        .collection('customer').doc(id).delete();
    store.addNotification({
        title: "Proof Reading",
        message: "user successfully deleted",
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 2000,
            onScreen: true
        }
    });
    return () => db;
};

export const updateUserTableData = (id, val) => async dispatch => {
    const data = {
        request: val
    };
    // console.log(id + '-----' + val);
    db
        .collection('customer').doc(id).update(data)
        .then((res) => {
            // console.log(res);
            // var array = [];
            //  db
            //     .collection("customer").get().then((querySnapshot) => {
            //         querySnapshot.forEach((doc) => {
            //             array.push(doc.data());
            //             console.log(array);
            //         });
            //         dispatch({
            //             type: 'GET_ALL_CUSTOMERS',
            //             payload: array
            //         })
            //     })
            //     .then((res)=>{
            //         console.log(res);
            //     })
            //     .catch((err)=>{
            //         console.log(err.code);
            //     });
            db
                .collection('customer')
                .doc(id)
                .get()
                .then((snapshot) => {
                    // console.log(snapshot.data());
                    dispatch({
                        type: 'GET_CUSTOMER_NAME',
                        payload: snapshot.data().name,
                    });
                    dispatch({
                        type: 'GET_INVOICE_NUMBER',
                        payload: snapshot.data().invoiceNumber,
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
        })
        .catch((error) => {
            console.log(error);
        })
};

export const updateUserTableAmendmentData = (id, val) => async dispatch => {
    const data = {
        amendment: val
    };
    // console.log(id + '-----' + val);
    db
        .collection('customer').doc(id).update(data)
        .then((res) => {
            // console.log(res);
            // var array = [];
            // db
            //     .collection("customer").get().then((querySnapshot) => {
            //     querySnapshot.forEach((doc) => {
            //         array.push(doc.data());
            //         console.log(array);
            //     });
            //     dispatch({
            //         type: 'GET_ALL_CUSTOMERS',
            //         payload: array
            //     })
            // })
            //     .then((res)=>{
            //         console.log(res);
            //     })
            //     .catch((err)=>{
            //         console.log(err.code);
            //     });
        })
        .catch((error) => {
            console.log(error);
        })
};

export const searchCustomerUsingName = (text, users) => async dispatch => {
    var array = [];
    users.map((item, i) => {
        console.log(item);
        if (item.name === text || item.invoiceNumber === text) {
            array.push(item);
            dispatch({
                type: 'GET_ALL_CUSTOMERS',
                payload: array
            })
        }
    })
};

export const getSearchTxt = (text) => async dispatch => {
    var array = [];
    // dispatch({
    //     type: 'GET_INPUT_SEARCH_TEXT',
    //     payload: text,
    // });
    await db
        .collection('customer')
        .orderBy("invoiceNumber").startAt(text).endAt(text + '\uf8ff')
        .onSnapshot(documentSnapshot => {
            documentSnapshot.forEach(doc => {
                // console.log(doc.data());
                array.push(doc.data());
            });
            // console.log(array);
            dispatch({
                type: 'GET_ALL_CUSTOMERS',
                payload: array
            })
        })
};

export const GetTemplate = (id, props) => async dispatch => {
    db
        .collection('template')
        .doc(id)
        .get()
        .then((snapshot) => {
            // console.log(snapshot.data());
            dispatch({
                type: 'GET_TEMPLATE_DATA_USING_UID',
                payload: snapshot.data(),
            });
            setTimeout(() => {
                props.history.push("/pdfDownload")
            }, 1000);
        })
        .catch((error) => {
            console.log(error.code);
        })
};

export const sendClientData = (title, author, affilication) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_TITLE_TXT',
        payload: title,
    });
    dispatch({
        type: 'GET_AND_SET_AUTHOR',
        payload: author,
    });
    dispatch({
        type: 'GET_AND_AFFILIATION',
        payload: affilication,
    });
};


export const LogOutAdmin = (props) => async dispatch => {
    auth
        .auth()
        .signOut()
        .then((res) => {
            // console.log(res);
            if (res === undefined) {
                setTimeout(() => {
                    props.history.push("/Login");
                    localStorage.clear()
                }, 1500);
            }
        })
        .catch((err) => {
            console.log(err.code);
        })
};
