import logo from '../assests/logo.png';
import React, {Component} from 'react'
import '../style/document.css';
import close from "../assests/close.png";
import logo1 from "../assests/footer.png";
import ReactCrop from "react-image-crop";
import {Avatar} from "@material-ui/core";
import {fireStorage} from "../Firebase";
import {connect} from "react-redux";
import {
    getCustomerName,
    getCompanyName,
    getCompanyLogoImage,
    getCompanySeal,
    getCustomerEmailAddress,
    getCustomerMobileNumber,
    getCustomerPost,
    getDescription,
    getDescriptionTwo,
    getHeadLine,
    getIssuesDate,
    getLogoTwo,
    getRegNumber,
    getSignatureImage,
    SaveTemplate,
    getFooterDescriptionOne,
    getFooterDescriptionTwo,
    getFooterDescriptionThree,
    getFooterDescriptionFore,
    getFooterDescriptionFive
} from "../actions/DocumentAction";
import {
    changeImageUrl
} from '../actions/SignUpActions';
import Picker from './common/picker';
import ReactNotification from "react-notifications-component";

class Document extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCustomerName = this.handleChangeCustomerName.bind(this);
        this.handleChangeCompanyName = this.handleChangeCompanyName.bind(this);
        this.handleChangePost = this.handleChangePost.bind(this);
        this.handleChangeRegNumber = this.handleChangeRegNumber.bind(this);
        this.handleChangeCustomerEmailAddress = this.handleChangeCustomerEmailAddress.bind(this);
        this.handleChangeCustomerMobileNumber = this.handleChangeCustomerMobileNumber.bind(this);
        this.handleChangeHeadLine = this.handleChangeHeadLine.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangeIssuesDate = this.handleChangeIssuesDate.bind(this);
        this.handleChangeSecondDescription = this.handleChangeSecondDescription.bind(this);
    }

    state = {
        value: "",
        src: null,
        crop: {
            unit: 'px', // default, can be 'px' or '%'
            x: 130,
            y: 50,
            width: 150,
            height: 150,
        },
        croppedImageUrl: null,
        cropComplete: {},

        src1: null,
        crop1: {
            unit: 'px', // default, can be 'px' or '%'
            x: 130,
            y: 50,
            width: 150,
            height: 100,
        },
        croppedImageUrl1: null,
        cropComplete1: {},
    };

    componentDidMount() {
        const user = localStorage.getItem("UID");
        // console.log(user);
        const userVal = localStorage.getItem("userData");
        if (userVal !== null) {
            // console.log(JSON.parse(userVal));
            const convertData = JSON.parse(userVal);
            const img = convertData.image;
            this.props.changeImageUrl(img);
            // console.log(img);
        }
        // console.log(JSON.parse(userVal));
    }

    handleChange(event) {
        this.setState({value: event.target.value})
    }

    handleChangeCustomerName(event) {
        // console.log(event.target.value);
        this.props.getCustomerName(event.target.value);
    }

    handleChangePost(event) {
        this.props.getCustomerPost(event.target.value);
    }

    handleChangeCompanyName(event) {
        this.props.getCompanyName(event.target.value);
    }

    handleChangeRegNumber(event) {
        this.props.getRegNumber(event.target.value);
    }

    handleChangeCustomerEmailAddress(event) {
        this.props.getCustomerEmailAddress(event.target.value);
    }

    handleChangeCustomerMobileNumber(event) {
        this.props.getCustomerMobileNumber(event.target.value);
    }

    handleChangeHeadLine(event) {
        this.props.getHeadLine(event.target.value);
    }

    handleChangeDescription(event) {
        this.props.getDescription(event.target.value);
    }

    handleChangeIssuesDate(event) {
        this.props.getIssuesDate(event.target.value);
    }


    handleChangeSecondDescription(event) {
        this.props.getDescriptionTwo(event.target.value);
    }

    // handleChangeFooterDescriptionOne(event) {
    //     console.log(event.target.value);
    //     this.props.getFooterDescriptionOne(event.target.value);
    // }

    // handleChangeFooterDescriptionTwo(event) {
    //     this.props.getFooterDescriptionTwo(event.target.value);
    // }

    // handleChangeFooterDescriptionThree(event) {
    //     this.props.getFooterDescriptionThree(event.target.value);
    // }

    // handleChangeFooterDescriptionFore(event) {
    //     this.props.getFooterDescriptionFore(event.target.value);
    // }

    // handleChangeFooterDescriptionFive(event) {
    //     this.props.getFooterDescriptionFive(event.target.value);
    // }

    render() {
        return (
            <div className="d-flex align-items-center justify-content-center mainDocument">
                <ReactNotification/>
                <p className="position-absolute m-auto font-weight-bold" id="backPara" onClick={() => {
                    this.props.history.push("/Setting")
                }}>Back</p>

                {this.props.imgUrl === '' ? (
                    <Avatar
                        src={logo}
                        alt="Logo"
                        className="position-absolute m-auto font-weight-bold"
                        id="ClosePara"
                        style={{height: '50px', width: '50px'}}
                    />
                ) : (

                    <Avatar
                        src={this.props.imgUrl}
                        alt="Logo"
                        className="position-absolute m-auto font-weight-bold"
                        id="ClosePara"
                        style={{height: '50px', width: '50px'}}
                    />
                )}

                <div className="container subContainer pt-0 formDiv p-3">

                    <div className="container bg-white justify-content-center d-flex subDivNew ">

                        <div className="row form1">

                            <div className="col-xl-8 col-lg-8 col-md-8  h-100">

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock mb-1 mt-2">
                                    <label htmlFor="fname1">Name:</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth0">
                                        <input type="text" id="emailAddress2" className="form-control inputs"
                                               onChange={this.handleChangeCustomerName} value={this.props.D_name}/>
                                    </div>
                                </div>

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock mb-1">
                                    <label htmlFor="lname1">Post:</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth0">
                                        <input type="text" id="emailAddress2" className="form-control inputs"
                                               onChange={this.handleChangePost} value={this.props.post}/>
                                    </div>
                                </div>

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock mb-1">
                                    <label htmlFor="emailAddress2">Company:</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth0">
                                        <input type="email" id="emailAddress2" className="form-control inputs"
                                               onChange={this.handleChangeCompanyName} value={this.props.company}/>
                                    </div>
                                </div>

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock mb-1">
                                    <label htmlFor="phoneNumber">Register No:</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth0">
                                        <input type="text" id="phoneNumber" className="form-control inputs"
                                               onChange={this.handleChangeRegNumber}
                                               value={this.props.registration_no}/>
                                    </div>
                                </div>

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock mb-1">
                                    <label htmlFor="password2">Email Address:</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth1">
                                        <input type="text" id="password2" className="form-control inputs"
                                               onChange={this.handleChangeCustomerEmailAddress}
                                               value={this.props.email_Address}/>
                                    </div>
                                </div>

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock mb-1">
                                    <label htmlFor="confirmPassword2">Mobile</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth1">
                                        <input type="text" id="confirmPassword2" className="form-control inputs"
                                               onChange={this.handleChangeCustomerMobileNumber}
                                               value={this.props.mobile}/>
                                    </div>
                                </div>

                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 align-items-center d-flex flex-column pt-3">
                                <Picker palceHolder="Company logo" width={200} height={200} idd={"lb"}
                                        Cimages={this.props.companylogo}/>
                            </div>

                            <div className="align-items-center justify-content-center w-100 container pt-2">

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock mt-2 mb-1">
                                    <label htmlFor="fname1">Head Line:</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth2">
                                        <input type="text" id="emailAddress2" className="form-control inputs1"
                                               onChange={this.handleChangeHeadLine} value={this.props.head_line}/>
                                    </div>
                                </div>

                                <div
                                    className="form-group align-items-center w-100 setBlock mb-2 p-2">
                                    <textarea className="w-100 txtArea" placeholder="Description"
                                              onChange={this.handleChangeDescription} value={this.props.description}/>
                                </div>

                                {/*<div*/}
                                {/*    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock mb-1 ">*/}
                                {/*    <label htmlFor="fname1">Title:</label>*/}
                                {/*    <div className="d-inline-flex align-items-center justify-content-between setWidth2">*/}
                                {/*        <input type="text" id="emailAddress2" className="form-control inputs1" disabled={true}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<div*/}
                                {/*    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock  mb-1">*/}
                                {/*    <label htmlFor="fname1">Author:</label>*/}
                                {/*    <div className="d-inline-flex align-items-center justify-content-between setWidth3">*/}
                                {/*        <input type="text" id="emailAddress2" className="form-control inputs1" disabled={true}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<div*/}
                                {/*    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock mb-1 ">*/}
                                {/*    <label htmlFor="fname1">Affiliation:</label>*/}
                                {/*    <div className="d-inline-flex align-items-center justify-content-between setWidth3">*/}
                                {/*        <input type="text" id="emailAddress2" className="form-control inputs1" disabled={true}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div
                                    className="form-group align-items-center justify-content-between d-inline-flex w-100 setBlock mb-3 ">
                                    <label htmlFor="fname1">Date of issued:</label>
                                    <div className="d-inline-flex align-items-center justify-content-between setWidth3">
                                        <input type="text" id="emailAddress2" className="form-control inputs1"
                                               onChange={this.handleChangeIssuesDate}
                                               value={this.props.date_of_issued}/>
                                    </div>
                                </div>

                            </div>

                            <div className="w-100 row justify-content-between mt-2 pl-4 mb-3">

                                <div
                                    className="col-xl-3 col-lg-3 col-md-3 h-100 justify-content-center align-items-center d-flex flex-column">
                                    <Picker palceHolder="Signature" width={160} height={100} idd={"lb3"}
                                            Cimages={this.props.signature_img}/>
                                </div>

                                <div
                                    className="col-xl-5 col-lg-5 col-md-5 h-100 justify-content-center align-items-center d-flex flex-column">
                                    <Picker palceHolder="Company Seal" width={250} height={100} idd={"lb2"}
                                            Cimages={this.props.company_seal}/>
                                </div>

                                <div
                                    className="col-xl-3 col-lg-3 col-md-3 h-100 justify-content-center align-items-center d-flex flex-column">
                                    <Picker palceHolder="logo 2" width={150} height={120} idd={"lb1"}
                                            Cimages={this.props.logo_two}/>
                                </div>

                            </div>

                            {/*<div*/}
                            {/*    className="form-group align-items-center w-100 setBlock mb-1 mt-2 container">*/}
                            {/*    <textarea className="txtArea1" placeholder="Description"*/}
                            {/*              onChange={this.handleChangeSecondDescription}*/}
                            {/*              value={this.props.description_two}/>*/}
                            {/*</div>*/}
                            <div
                                className="form-group align-items-center setBlock mb-3 footerView">
                                <div className="d-inline-flex align-items-center justify-content-between setWidth5">
                                    <input type="text" id="emailAddress2" className="form-control inputs1"
                                           onChange={(event)=>{
                                               this.props.getFooterDescriptionOne(event.target.value);
                                           }}
                                           value={this.props.footer_Description_one}/>
                                </div>
                                <label htmlFor="fname1" className="labelTxtN">Documents Proofread/Edited/Structured/Paraphrased</label>
                            </div>
                            <div
                                className="form-group align-items-center setBlock mb-3 footerView">
                                <div className="d-inline-flex align-items-center justify-content-between setWidth5">
                                    <input type="text" id="emailAddress2" className="form-control inputs1"
                                           onChange={(event)=>{
                                               this.props.getFooterDescriptionTwo(event.target.value);
                                           }}
                                           value={this.props.footer_Description_two}/>
                                </div>
                                <label htmlFor="fname1" className="labelTxtN">Articles Accepted in SSCI/SCI/HSCI/Scopus/ERA</label>
                            </div>
                            <div
                                className="form-group align-items-center setBlock mb-3 footerView">
                                <div className="d-inline-flex align-items-center justify-content-between setWidth5">
                                    <input type="text" id="emailAddress2" className="form-control inputs1"
                                           onChange={(event)=>{
                                               this.props.getFooterDescriptionThree(event.target.value);
                                           }}
                                           value={this.props.footer_Description_three}/>
                                </div>
                                <label htmlFor="fname1" className="labelTxtN">Theses Passed Viva</label>
                            </div>
                            <div
                                className="form-group align-items-center setBlock mb-3 footerView">
                                <div className="d-inline-flex align-items-center justify-content-between setWidth5">
                                    <input type="text" id="emailAddress2" className="form-control inputs1"
                                           onChange={(event)=>{
                                               this.props.getFooterDescriptionFore(event.target.value);
                                           }}
                                           value={this.props.footer_Description_fore}/>
                                </div>
                                <label htmlFor="fname1" className="labelTxtN">Grant Documents Approved</label>
                            </div>
                            <div
                                className="form-group align-items-center setBlock mb-3 footerView">
                                <div className="d-inline-flex align-items-center justify-content-between setWidth5">
                                    <input type="text" id="emailAddress2" className="form-control inputs1"
                                           onChange={(event)=>{
                                               this.props.getFooterDescriptionFive(event.target.value);
                                           }}
                                           value={this.props.footer_Description_five}/>
                                </div>
                                <label htmlFor="fname1" className="labelTxtN">CV and Research Statements Completed</label>
                            </div>

                            <div className="w-100 p-2">
                                <img src={logo1} alt={"Logo"} className="footerImg"/>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="FooterView">
                    <button className="FooterBtnDocument btn" onClick={() => {
                        this.props.SaveTemplate(
                            this.props.D_name,
                            this.props.post,
                            this.props.company,
                            this.props.registration_no,
                            this.props.email_Address,
                            this.props.mobile,
                            this.props.head_line,
                            this.props.companylogo,
                            this.props.description,
                            this.props.date_of_issued,
                            this.props.description_two,
                            this.props.signature_img,
                            this.props.company_seal,
                            this.props.logo_two,
                            this.props.templateId,
                            this.props.footer_Description_one,
                            this.props.footer_Description_two,
                            this.props.footer_Description_three,
                            this.props.footer_Description_fore,
                            this.props.footer_Description_five
                        )
                    }}>
                        Save
                    </button>
                </div>

                {
                    this.state.src !== null ?
                        <div className="container position-absolute h-75">
                            <div
                                className=" h-100 flex-column justify-content-center align-items-center d-flex ">

                                <ReactCrop
                                    src={this.state.src}
                                    crop={this.state.crop}
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                    locked={true}
                                />

                                <button className="btn FooterBtnSignUp position-relative mt-5"
                                        onClick={this.onFinsih}>Crop
                                </button>
                            </div>
                        </div>
                        : undefined
                }

                {
                    this.state.src1 !== null ?
                        <div className="container position-absolute h-75">
                            <div
                                className=" h-100 flex-column justify-content-center align-items-center d-flex ">

                                <ReactCrop
                                    src={this.state.src1}
                                    crop={this.state.crop1}
                                    onImageLoaded={this.onImageLoaded1}
                                    onComplete={this.onCropComplete1}
                                    onChange={this.onCropChange1}
                                    locked={true}
                                />

                                <button className="btn FooterBtnSignUp position-relative mt-5"
                                        onClick={this.onFinsih1}>Crop
                                </button>
                            </div>
                        </div>
                        : undefined
                }
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        D_name: state.documentReducer.D_name,
        post: state.documentReducer.post,
        company: state.documentReducer.company,
        registration_no: state.documentReducer.registration_no,
        email_Address: state.documentReducer.email_Address,
        mobile: state.documentReducer.mobile,
        head_line: state.documentReducer.head_line,
        companylogo: state.documentReducer.companylogo,
        description: state.documentReducer.description,
        date_of_issued: state.documentReducer.date_of_issued,
        signature_img: state.documentReducer.signature_img,
        company_seal: state.documentReducer.company_seal,
        logo_two: state.documentReducer.logo_two,
        description_two: state.documentReducer.description_two,
        imgUrl: state.signUp.imgUrl,
        templateId: state.setting.templateId,
        footer_Description_one: state.documentReducer.footer_Description_one,
        footer_Description_two: state.documentReducer.footer_Description_two,
        footer_Description_three: state.documentReducer.footer_Description_three,
        footer_Description_fore: state.documentReducer.footer_Description_fore,
        footer_Description_five: state.documentReducer.footer_Description_five,
    }
};

export default connect(
    mapStateToProps,
    {
        getCustomerName,
        getCompanyName,
        getCompanyLogoImage,
        getCompanySeal,
        getCustomerEmailAddress,
        getCustomerMobileNumber,
        getCustomerPost,
        getDescription,
        getDescriptionTwo,
        getHeadLine,
        getIssuesDate,
        getLogoTwo,
        getRegNumber,
        getSignatureImage,
        SaveTemplate,
        changeImageUrl,
        getFooterDescriptionOne,
        getFooterDescriptionTwo,
        getFooterDescriptionThree,
        getFooterDescriptionFore,
        getFooterDescriptionFive
    },
)(Document);
