import {Component} from 'react'
import React from "react";
import '../style/template.css';
import log3 from "../assests/logo3.png";
import log4 from "../assests/logo4.png";
import footer from "../assests/footer.png";
import {Page, Text, View, Document, StyleSheet, Image,} from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        // backgroundColor: 'green',
        marginTop: 30,
    },
    section: {
        backgroundColor: '#E6E6FA',
        // paddingTop: 10,
        // paddingBottom: 10,
        width: '93%',
        paddingLeft: 10,
        // margin: 10,
        // paddingTop: 30,
        // marginTop: -10,
        // padding: 5,
    },
    headerView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        paddingRight: 10,
        paddingLeft: 10,
        marginBottom: 10,
        //backgroundColor: 'red',
        // backgroundColor:'#DCDCDC',
    },
    logoStyle: {
        width: 150,
        height: 160,
        position: 'absolute',
        right: 0,
    },
    txt: {
        fontSize: 11,
        lineHeight: 1.3,
        paddingLeft: 12,
        // fontWeight: 'bold',
    },
    txt3: {
        fontSize: 10,
        lineHeight: 1.3,
        // marginLeft: 10,
        // paddingLeft: 10,
        // fontWeight: 'bold',
    },
    txt4: {
        fontSize: 11,
        lineHeight: 1.3,
        paddingLeft: 5,
        // paddingLeft: 10,
        // fontWeight: 'bold',
    },
    txt2: {
        fontSize: 13,
        color: 'green',
        lineHeight: 1.3,
        fontWeight: 'bold',
    },
    txtFooter: {
        fontSize: 11,
        lineHeight: 1.5,
        color: '#696969',
        marginLeft: 5
    },
    txtFooter3: {
        fontSize: 11,
        lineHeight: 1.5,
        color: '#000',
        fontWeight: 'bold'
    },
    txtFooter1: {
        fontSize: 10,
        lineHeight: 1.5,
        marginTop: 10
    },
    txtC: {
        fontSize: 14,
        lineHeight: 1.5,
        fontWeight: 'bold',
        paddingLeft: 10,
    },
    txtDate: {
        fontSize: 11,
        paddingLeft: 10,
        lineHeight: 1.5,
        // marginBottom: 20
    },
    titletxt: {
        fontSize: 11,
        lineHeight: 1.5,
        marginBottom: 10,
        paddingLeft: 10
    },

    titletxt5: {
        fontSize: 10,
        verticalAlign: 'super',
        // lineHeight: 1.5,
        // marginBottom: 10,
        // paddingLeft: 10
    },

    titletxt1: {
        fontSize: 11,
        lineHeight: 1.5,
        paddingLeft: 10,
        // marginBottom: 10,
        textAlign: 'justify',
    },

    titleView: {
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
        marginTop: '5%',
    },
    title: {
        fontSize: 17,
        color: 'black',
        fontWeight: 'bold',
    },
    bodyView: {
        width: '88%',
        paddingLeft: 2,
        // backgroundColor: 'red'
    },
    signatureView: {
        width: '30%',
        //backgroundColor: 'yellow',
        // paddingTop: 20,
        marginRight: '55%',
        marginTop: '5%',
        // paddingRight: 10,
        // paddingLeft: 25
    },
    signaturePic: {
        width: '85%',
        //backgroundColor: 'pink',
        // paddingRight: 10,
        // paddingLeft: 25,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        //marginTop: -5,
        // marginBottom: 5,
    },
    pic1: {
        width: 160,
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'red',
    },
    pic2: {
        width: 250,
        height: 150,
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'green',
    },
    logo2Pic: {
        width: '88%',
        marginTop: 20,
        height: 110,
        // paddingRight: 10,
        // paddingLeft: 25,
        flexDirection: 'row',
        //backgroundColor: 'blue',
        justifyContent: 'space-between',
        // marginBottom: 10
    },
    footerView: {
        width: '70%',
        height: 100,
        paddingLeft: 5,
        // paddingRight: 10,
        // paddingLeft: 25,
        // backgroundColor: 'green',
    },
    footerTxtView: {
        width: '100%',
        flexDirection: 'row',
        //justifyContent: 'space-between',
        // height: 100,
        // paddingLeft: 5,
        // paddingRight: 10,
        // paddingLeft: 25,
        //backgroundColor: 'white',
    },
    logoStyle1: {
        width: 100,
        height: 80,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '3%'
    },
    logoStyle2: {
        width: 200,
        height: 130,
        marginTop: '2%',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // marginLeft: '20%'
    },
    logoStyle3: {
        width: 130,
        height: 90
    },
    logoStyle4: {
        width: 160,
        height: 100
    },
    footerImg: {
        width: '88%',
        height: 10,
        marginTop: '2%',
        paddingLeft: 10,
    }
});

const ref = React.createRef();

class Template extends Component {

    constructor(props) {
        super(props);
        console.log(this.props.data);
        this.handleChange = this.handleChange.bind(this);
    }

    state = {
        value: ""
    };

    componentDidMount() {
        console.log('companyLogo : ' + this.props.data.companyLogo);
        console.log('signathure : ' + this.props.data.signatureImage);
        console.log('companySeal : ' + this.props.data.companySeal);
        console.log('logoTwo :' + this.props.data.logoTwo);
    }

    handleChange(event) {
        this.setState({value: event.target.value})
    }

    render() {
        // const clogo = this.props.data.companyLogo;
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.headerView}>
                        <View style={styles.section}>
                            <Text style={[styles.txt2]}>{this.props.data.name}</Text>
                            <Text style={styles.txt3}>{this.props.data.post},</Text>
                            <Text style={styles.txt3}>Company:{' '}{this.props.data.company}</Text>
                            <Text style={styles.txt3}>Registration:{' '}{this.props.data.registorNumber}</Text>
                            <Text style={styles.txt3}>Email:{' '}{this.props.data.emailAddress}</Text>
                            <Text style={styles.txt3}>Mobile:{' '}{this.props.data.mobile}</Text>
                        </View>
                        {/*<Image source={{uri: this.props.data.companyLogo}} style={styles.logoStyle}/>*/}
                        <Image source={log3} style={styles.logoStyle}/>
                    </View>

                    <View style={styles.titleView}>
                        <Text style={styles.title}>{this.props.data.headLine}</Text>
                    </View>

                    <View style={styles.bodyView}>
                        <Text style={styles.titletxt1}>{this.props.data.description}</Text>
                        <View style={{marginTop: '3%'}}>
                            <Text style={styles.txtC}>Title:</Text>
                            <Text style={styles.titletxt}>{this.props.titleTxt}</Text>

                            <Text style={styles.txtC}>Authors:</Text>
                            <Text style={styles.titletxt}>{this.props.author}</Text>

                            <Text style={styles.txtC}>Affiliations:</Text>
                            <Text style={styles.titletxt}>{this.props.affiliation}</Text>

                            <Text style={styles.txtDate}>Date Issued: {this.props.data.issuessDate}</Text>
                        </View>
                    </View>

                    <View style={styles.signatureView}>
                        <Text style={styles.txt4}>Yours truly,</Text>
                    </View>

                    <View style={styles.signaturePic}>
                        {/*<Image source={{uri: this.props.data.signatureImage}} style={styles.logoStyle1}/>*/}
                        <Image source={log4} style={styles.logoStyle1}/>
                        <View style={styles.pic2}>
                            {/*<Image source={{uri: this.props.data.companySeal}} style={styles.logoStyle2}/>*/}
                            <Image source={log3} style={styles.logoStyle2}/>
                        </View>
                        <View>
                            {/*<Image source={{uri: this.props.data.logoTwo}} style={styles.logoStyle3}/>*/}
                            <Image source={log4} style={styles.logoStyle3}/>
                            <Image source={log3} style={styles.logoStyle3}/>
                        </View>
                    </View>
                    <View style={styles.logo2Pic}>
                        <View style={styles.footerView}>
                            <View style={styles.footerTxtView}>
                                <Text style={styles.txtFooter3}>{this.props.data.footerDescriptionOne}</Text>
                                <Text style={styles.txtFooter}>Documents Proofread/Edited/Structured/Paraphrased</Text>
                            </View>
                            <View style={styles.footerTxtView}>
                                <Text style={styles.txtFooter3}>{this.props.data.footerDescriptionTwo}</Text>
                                <Text style={styles.txtFooter}>Articles Accepted in SSCI/SCI/HSCI/Scopus/ERA</Text>
                            </View>
                            <View style={styles.footerTxtView}>
                                <Text style={styles.txtFooter3}>{this.props.data.footerDescriptionThree}</Text>
                                <Text style={styles.txtFooter}>Theses Passed Viva</Text>
                            </View>
                            <View style={styles.footerTxtView}>
                                <Text style={styles.txtFooter3}>{this.props.data.footerDescriptionFore}</Text>
                                <Text style={styles.txtFooter}>Grant Documents Approved</Text>
                            </View>
                            <View style={styles.footerTxtView}>
                                <Text style={styles.txtFooter3}>{this.props.data.footerDescriptionFive}</Text>
                                <Text style={styles.txtFooter}>CV and Research Statements Completed</Text>
                            </View>
                            {/*<Text style={styles.txtFooter}>{this.props.data.footerDescriptionOne} Documents Proofread/Edited/Structured/Paraphrased</Text>*/}
                            {/*<Text style={styles.txtFooter}>{this.props.data.footerDescriptionTwo} Articles Accepted in SSCI/SCI/HSCI/Scopus/ERA</Text>*/}
                            {/*<Text style={styles.txtFooter}>{this.props.data.footerDescriptionThree} Theses Passed Viva</Text>*/}
                            {/*<Text style={styles.txtFooter}>{this.props.data.footerDescriptionFore} Grant Documents Approved</Text>*/}
                            {/*<Text style={styles.txtFooter}>{this.props.data.footerDescriptionFive}  CV and Research Statements Completed</Text>*/}
                        </View>
                        <View style={styles.pic1}>
                            <Image source={log4} style={styles.logoStyle4}/>
                        </View>
                    </View>
                    <Text style={{
                        color: 'gray',
                        fontSize: 11,
                        alignItems: 'flex-start',
                        width: '85%',
                        justifyContent: 'flex-start'
                    }}>
                        Remaining documents are all under review
                    </Text>
                    <Image source={footer} style={styles.footerImg}/>

                </Page>
            </Document>
        );
    }
}

export default Template;
