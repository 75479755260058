const INITIAL_STATE = {
    firstName: '',
    lastName: '',
    contactNumber: null,
    email: '',
    password: '',
    cmPassword: '',
    imgUrl: '',
    userID: '',
    userData: [],
    fName: '',
    lName: '',
    emailAddress: '',
    phoneNumber: '',
    imgName: '',
    imgPath: [],
    newPassword: '',
    confirmPassword: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CHANGE_FIRST_NAME':
            return { ...state, firstName: action.payload };
        case 'CHANGE_LAST_NAME':
            return { ...state, lastName: action.payload };
        case 'CHANGE_EMAIL':
            return {...state, email: action.payload};
        case 'CHANGE_PASSWORD':
            return {...state, password: action.payload};
        case 'CHANGE_CONTACT_NUMBER':
            return {...state, contactNumber: action.payload};
        case 'CHANGE_IMAGE_URL':
            return {...state, imgUrl: action.payload};
        case 'GET_USER_ID':
            return {...state, userID: action.payload};
        case 'GET_LOGIN_USER_DATA':
            return {...state, userData: action.payload};
        case 'GET_AND_SET_FIRST_NAME':
            return {...state, fName: action.payload};
        case 'GET_AND_SET_LAST_NAME':
            return {...state, lName: action.payload};
        case 'GET_AND_SET_EMAIL_ADDRESS':
            return {...state, emailAddress: action.payload};
        case 'GET_AND_SET_CONTACT_NUMBER':
            return {...state, phoneNumber: action.payload};
        case 'PICK_IMG_NAME':
            return {...state, imgName: action.payload};
        case 'PICK_IMG_PATH':
            return {...state, imgPath: action.payload};
        case 'NEW_PASSWORD':
            // console.log('newPassword');
            return {...state, newPassword: action.payload};
        case 'CONFIRM_PASSWORD':
            // console.log('confirmPassword');
            return {...state, confirmPassword: action.payload};
        case 'CM_PASSWORD':
            // console.log('confirmPassword');
            return {...state, cmPassword: action.payload};
        default:
            return state;
    }
};
