import {db} from "../Firebase";
import {store} from "react-notifications-component";
import BaseUrl from "../url/BaseUrl";

export const updateUserTable = (id, val) => async dispatch => {
    const data = {
        request: val
    };
    // console.log(id + '-----' + val);
    db
        .collection('customer').doc(id).update(data)
        .then((res) => {
            // console.log(res);
            if (res === undefined) {
                store.addNotification({
                    title: "Proof Reading",
                    message: "Request Successfully Send",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1500,
                        onScreen: true
                    }
                });
            }
        })
        .catch((error) => {
            console.log(error);
        })
};

export const updateUserTableAmendment = (id, val, date, id1) => async dispatch => {
    const data = {
        amendment: val,
    };
    //  console.log(id + '-----' + val);
    // console.log(id + '-----' + val);
    db
        .collection('customer').doc(id).update(data)
        .then((res) => {
            const data1 = {
                download_date: date
            };
            if (val === 'finish'){
                // console.log('METHOD CALL');
                // console.log(id1);
                // console.log(data1);
                db
                    .collection('template').doc(id1).update(data1)
                    .then(()=> {

                    })
                    .catch((error)=> {
                        console.log(error);
                    })
            }
            // console.log(res);
        })
        .catch((error) => {
            console.log(error);
        })
};

export const sendEmail = (name, type, link, invoiceNumber) => async dispatch => {
    await BaseUrl.post('/emails/send', {
        // "to":"dilanlahiruijse@gmail.com",
        // "subject": "Proof Reading",
        // "html": `Dear Admin, <br/> <p> ${name}, ${invoiceNumber} has requested for ${type} <br/> <p> Please respond via Cop tool <br/> <p> ${link}`
        "to": "ProofreadingByPhd@gmail.com",
        "subject": "Proof Reading",
        "html": `Dear Admin, <br/> <p> ${name}, ${invoiceNumber} has requested for ${type} <br/> <p> Please respond via Cop tool <br/> <p> ${link}`
    })
        .then(async response => {
            // console.log(response)
        })
        .catch(err => {
            console.log(err.message)
        })
};

// export const getPDFData = (id) => async dispatch => {
//     await .get('')
//         .then(response => {
//         })
//         .catch(function(error) {
//             console.log(error);
//         });
// };
