import {db} from '../Firebase';
import {store} from "react-notifications-component";

export const getTitleTxt = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_TITLE_TXT',
        payload: text,
    });
};

export const getAuthor = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_SET_AUTHOR',
        payload: text,
    });
};

export const getAffiliation = (text) => async dispatch => {
    dispatch({
        type: 'GET_AND_AFFILIATION',
        payload: text,
    });
};

export const getLogClientID = (text) => async dispatch => {
    // console.log(text);
    dispatch({
        type: 'GET_AND_SET_UNIQ_CLIENT_ID',
        payload: text,
    });
};

export const getUserData = (id, props) => async dispatch => {
    // console.log(id);
    // console.log(props);
    db
        .collection('customer')
        .doc(id)
        .get()
        .then((snapshot) => {
            //console.log(snapshot.data());
            dispatch({
                type: 'GET_USER_DATA_USING_UID',
                payload: snapshot.data(),
            });
            dispatch({
                type: 'GET_AND_SET_AUTHOR',
                payload: snapshot.data().author,
            });
            dispatch({
                type: 'GET_AND_AFFILIATION',
                payload: snapshot.data().affiliation,
            });
            dispatch({
                type: 'GET_AND_SET_TITLE_TXT',
                payload: snapshot.data().title,
            });
            dispatch({
                type: 'LOAD_CLIENT_NAME',
                payload: snapshot.data().name,
            });
            dispatch({
                type: 'LOAD_CLIENT_GENERATE_LINK',
                payload: snapshot.data().link,
            });
            dispatch({
                type: 'LOAD_CLIENT_INVOICE_NUMBER',
                payload: snapshot.data().invoiceNumber,
            });
            localStorage.setItem('CustomerName',snapshot.data().name);
            localStorage.setItem('InvoiceNumber',snapshot.data().invoiceNumber);
            setTimeout(() => {
                if (snapshot.data().amendment === 'Pending' || snapshot.data().amendment === 'finish' || snapshot.data().amendment === 'reject') {
                    props.history.push('/downlode');
                    // if (snapshot.data().request === 'accept' || snapshot.data().request === 'Pending' || snapshot.data().request === 'reject'){
                    //     props.history.push('/downlode');
                    // }
                } else if (snapshot.data().amendment === 'accept' || snapshot.data().amendment === undefined) {
                    props.history.push('/Client');
                }
            }, 1000);
            // console.log(snapshot.data().amendment);
        })
        .catch((error) => {
            console.log(error.code);
        })
};

export const getUserDataToPDF = (id) => async dispatch => {
    // console.log(id);
    db
        .collection('customer')
        .doc(id)
        .get()
        .then((snapshot) => {
            // console.log(snapshot.data());
            dispatch({
                type: 'GET_USER_DATA_USING_UID',
                payload: snapshot.data(),
            });
            dispatch({
                type: 'GET_AND_SET_AUTHOR',
                payload: snapshot.data().author,
            });
            dispatch({
                type: 'GET_AND_AFFILIATION',
                payload: snapshot.data().affiliation,
            });
            dispatch({
                type: 'GET_AND_SET_TITLE_TXT',
                payload: snapshot.data().title,
            });
        })
        .catch((error) => {
            console.log(error.code);
        })
};

export const getTemplateData = (id) => async dispatch => {
    // console.log(id);
    db
        .collection('template')
        .doc(id)
        .get()
        .then((snapshot) => {
             //console.log(snapshot.data());
            dispatch({
                type: 'GET_TEMPLATE_DATA_USING_UID',
                payload: snapshot.data(),
            })
        })
        .catch((error) => {
            console.log(error.code);
        })
};

export const addedNewData = (id, name, author, affiliation, props) => async dispatch => {
    const data = {
        title: name,
        author: author,
        affiliation: affiliation
    };
    db.collection('customer').doc(id).update(data)
        .then((res) => {
            // console.log(res);
            if (res === undefined) {
                store.addNotification({
                    title: "Proof Reading",
                    message: "Save Successfully",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1500,
                        onScreen: true
                    }
                });
                setTimeout(() => {
                    props.history.push('/downlode');
                }, 1500);
            }
        })
        .catch((error) => {
            console.log(error);
        })
};
