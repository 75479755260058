// import logo from '../assests/logo.png';
// import React, {Component} from 'react'
// import '../style/home.css';
// import logo1 from '../assests/logo_black_orange.png';
// import Yes from '../assests/Yes.png';
// import Close from '../assests/No.png';
// import downlode from '../assests/downlode.png';
// import {connect} from "react-redux";
// import {Avatar} from "@material-ui/core";
// import LoadingOverlay from 'react-loading-overlay'
// import BounceLoader from 'react-spinners/PropagateLoader'
// import Table from 'react-bootstrap/Table'
// import {
//     changeImageUrl
// } from '../actions/SignUpActions';
// import {
//     getAllCustomers,
//     updateUserTableData,
//     searchCustomerUsingName,
//     getSearchTxt,
//     updateUserTableAmendmentData,
//     GetTemplate,
//     sendClientData,
//     LogOutAdmin,
//     deleteCustomer,
// } from '../actions/HomeAction';
// import {
//     getCustomerName,
//     getGenarateid,
//     getGenarateLink,
//     getSelectType,
//     getInvoiceNumber,
// } from '../actions/LinkAction';
// import {
//     getSelectedMapTid,
//     getSelectedMapUid,
// } from '../actions/PdfDownloadAction';
// import Resources from '../Resource/resources';
// import {PDFDownloadLink} from "@react-pdf/renderer";
// import MyDoc from "./Template";
// import ReactNotification, {store} from "react-notifications-component";
// import 'react-notifications-component/dist/theme.css';
// import 'animate.css';
// import {confirmAlert} from "react-confirm-alert";
//
// class Home extends Component {
//
//     constructor(props) {
//         super(props);
//         this.handleChange = this.handleChange.bind(this);
//         this.handleUpDate = this.handleUpDate.bind(this);
//         this.handleSearchCustomers = this.handleSearchCustomers.bind(this);
//         this.handleSearchTxt = this.handleSearchTxt.bind(this);
//         this.handleAmendment = this.handleAmendment.bind(this);
//         this.copyVal = this.copyVal.bind(this);
//         this.downloadPDF = this.downloadPDF.bind(this);
//         this.logout = this.logout.bind(this);
//     }
//
//     componentDidMount() {
//         document.body.style.backgroundColor = "#6F7E84"
//         this.props.getAllCustomers();
//         const user = localStorage.getItem("UID");
//         // console.log(user);
//         const userVal = localStorage.getItem("userData");
//         if (userVal !== null) {
//             //console.log(JSON.parse(userVal));
//             const convertData = JSON.parse(userVal);
//             const img = convertData.image;
//             this.props.changeImageUrl(img);
//             //console.log(img);
//         }
//         Resources.Id = '';
//         //console.log(JSON.parse(userVal));
//
//         if (user === null) {
//             this.props.history.push("/Login");
//         }
//         setTimeout(() => {
//             this.setState({
//                 indicator: false
//             })
//         }, 3000);
//     }
//
//     state = {
//         value: "",
//         indicator: true,
//     };
//
//     handleChange(event) {
//         this.setState({value: event.target.value})
//     }
//
//     handleUpDate(id, val) {
//         this.props.updateUserTableData(id, val);
//         if (val !== 'reject') {
//             this.props.history.push("/Link");
//         }
//     }
//
//     handleAmendment(id, val) {
//         this.props.updateUserTableAmendmentData(id, val)
//     }
//
//     handleSearchCustomers() {
//         this.props.searchCustomerUsingName(this.props.searchTxt, this.props.customer);
//     }
//
//     handleSearchTxt(event) {
//         this.props.getSearchTxt(event.target.value);
//     }
//
//     copyVal(text) {
//         // alert('Link copied to clipboard');
//         navigator.clipboard.writeText(text).then(function () {
//             store.addNotification({
//                 title: "Proof Reading",
//                 message: "Link copied to clipboard",
//                 type: "warning",
//                 insert: "top",
//                 container: "top-right",
//                 animationIn: ["animate__animated", "animate__fadeIn"],
//                 animationOut: ["animate__animated", "animate__fadeOut"],
//                 dismiss: {
//                     duration: 1500,
//                     onScreen: true
//                 }
//             });
//         }, function (err) {
//             console.error('Async: Could not copy text: ', err);
//         });
//     }
//
//     downloadPDF(id, title, author, affilication) {
//         this.props.GetTemplate(id, this.props);
//         this.props.sendClientData(
//             title,
//             author,
//             affilication
//         );
//     }
//
//     logout() {
//         this.props.LogOutAdmin(this.props);
//     }
//
//
//     render() {
//         return (
//             <LoadingOverlay active={this.state.indicator}
//                             spinner={<BounceLoader color="#FFA300" size={20}/>}>
//               <div className="container-fluid">
//                   <div className="row">
//                       <div className="col-lg-12 col-md-12 col-sm-12">
//                           <ReactNotification/>
//                           <div className="header-Container container">
//                               <div className="LogoView">
//                                   <img src={logo1} alt={"Logo"} className="HeaderlogoHome"/>
//                               </div>
//                               <div>
//                                   {this.props.imgUrl === '' ? (
//                                       <Avatar
//                                           src={logo}
//                                           alt="Logo"
//                                           id="homeLogo"
//                                           className="userProfileLogo"
//                                           onClick={() => {
//                                                this.props.history.push("/SignUp")
//                                               //this.props.history.push("/PdfScreen/CKiE202pwDnqFngBZ8uc/Vr2A0nIpXxP9Cm5h8wKb")
//                                           }}
//                                       />
//                                   ) : (
//                                       <Avatar
//                                           src={this.props.imgUrl}
//                                           alt="Logo"
//                                           id="homeLogo"
//                                           className="userProfileLogo"
//                                           onClick={() => {
//                                               // this.props.history.push("/PdfScreen/0HWRESamV9ZvLfbUJpcb/Vr2A0nIpXxP9Cm5h8wKb")
//                                               //this.props.history.push("/PdfScreen/CKiE202pwDnqFngBZ8uc/Vr2A0nIpXxP9Cm5h8wKb")
//                                               this.props.history.push("/SignUp")
//                                           }}
//                                       />
//                                   )}
//                               </div>
//                           </div>
//
//                           <div className="subView container align-items-center mb-3">
//                               <h1 className="headerTextHome container">CLIENT LIST</h1>
//                               <div className="flex-row d-flex search_div">
//                                   <input type="link" name="name" className="form-control searchTxt"
//                                          id="searchTxtInput" placeholder="Search" onChange={this.handleSearchTxt}/>
//                                   <div className="searchBtnHome btn">Search
//                                   </div>
//                               </div>
//                           </div>
//
//                           <div className="container tableView  overflow-auto table-responsive">
//                               <table className="table table-borderless tableStyle overflow-auto">
//                                   <thead>
//                                   <tr>
//                                       <th className=" tblHeadTxt">Name</th>
//                                       <th className="tblHeadTxt">Email</th>
//                                       <th className="tblHeadTxt">Link</th>
//                                       <th className="tblHeadTxt"></th>
//                                       <th className="tblHeadTxt"></th>
//                                   </tr>
//                                   </thead>
//                                   <tbody>
//                                   {this.props.customer.map((item, i) => (
//                                       <tr id="rowStyle">
//                                           <td className="tdStyle">
//                                               <div className="tablefirsttab">
//                                                   {item.name}
//                                               </div>
//                                           </td>
//                                           {/*style={{width: 400}}*/}
//                                           {/*style={{width: 200}}*/}
//                                           <td className="tblValTxt">{item.invoiceNumber}</td>
//                                           <td className="tblValTxt"
//                                               title={item.link} id="link-td" onClick={() => {
//                                               this.copyVal(item.link)
//                                           }}>
//                                               {item.link.slice(0, 40)}...
//                                           </td>
//                                           <td className="align-items-center justify-content-center">
//                                               <div className="flex-row  justify-content-end d-flex align-items-center">
//                                                   <div className="tblRowbtnView ">
//                                                       {item.request === "Pending" ? (
//                                                           <button className="rowBtnHome" disabled={true}>New Link
//                                                           </button>
//                                                       ) : (
//                                                           undefined
//                                                       )}
//                                                       {item.amendment === "Pending" ? (
//                                                           <button className="rowBtnHome" disabled={true}>Amendment
//                                                           </button>
//                                                       ) : (
//                                                           undefined
//                                                       )}
//                                                       {item.request === "Pending" ? (
//                                                           <div className="rowBtnHome1 row justify-content-around">
//                                                               <img src={Yes} alt={"Logo"} className="okBtn" onClick={() => {
//                                                                   this.handleUpDate(item.userId, 'accept');
//                                                                   Resources.Condition = false;
//                                                                   Resources.Id = item.userId;
//                                                               }}/>
//                                                               <img src={Close} alt={"Logo"} className="okBtn" onClick={() => {
//                                                                   this.handleUpDate(item.userId, 'reject');
//                                                               }}/>
//                                                           </div>
//                                                       ) : (
//                                                           undefined
//                                                       )}
//                                                       {item.amendment === "Pending" ? (
//                                                           <div className="rowBtnHome1 row justify-content-around">
//                                                               <img src={Yes} alt={"Logo"} className="okBtn" onClick={() => {
//                                                                   this.handleAmendment(item.userId, 'accept');
//                                                               }}/>
//                                                               <img src={Close} alt={"Logo"} className="okBtn" onClick={() => {
//                                                                   this.handleAmendment(item.userId, 'reject');
//                                                               }}/>
//                                                           </div>
//                                                       ) : (
//                                                           undefined
//                                                       )}
//                                                   </div>
//                                                   {item.title !== undefined || '' ? (
//                                                       <img src={downlode} alt={"Logo"} className="okBtnDownlode"
//                                                            onClick={() => {
//                                                                this.props.getSelectedMapTid(item.type)
//                                                                this.props.getSelectedMapUid(item.userId)
//                                                                this.props.history.push("/pdfDownload");
//                                                                // this.downloadPDF(item.type, item.title, item.author, item.affiliation)
//                                                            }}/>
//                                                   ) : (
//                                                       undefined
//                                                   )}
//                                               </div>
//                                           </td>
//                                           <td>
//                                               <svg xmlns="http://www.w3.org/2000/svg" width="20.751" height="20.943" viewBox="0 0 39.751 48.943"
//                                                    className="deleteBtn"
//                                               onClick={() => {
//                                                   this.deleteObj(item.name, item.userId);
//                                               }}
//                                               >
//                                                   <g id="Group_10698" data-name="Group 10698" transform="translate(0 0)">
//                                                       <path id="Path_10806" data-name="Path 10806" d="M223.545,154.7a1.146,1.146,0,0,0-1.146,1.146v21.663a1.146,1.146,0,0,0,2.292,0V155.849A1.146,1.146,0,0,0,223.545,154.7Zm0,0" transform="translate(-196.907 -136.971)"/>
//                                                       <path id="Path_10807" data-name="Path 10807" d="M105.545,154.7a1.146,1.146,0,0,0-1.146,1.146v21.663a1.146,1.146,0,0,0,2.292,0V155.849A1.146,1.146,0,0,0,105.545,154.7Zm0,0" transform="translate(-92.432 -136.971)"/>
//                                                       <path id="Path_10808" data-name="Path 10808" d="M3.252,14.57V42.81a6.324,6.324,0,0,0,1.681,4.361,5.645,5.645,0,0,0,4.1,1.771H30.716a5.643,5.643,0,0,0,4.1-1.771,6.324,6.324,0,0,0,1.681-4.361V14.57a4.379,4.379,0,0,0-1.123-8.61H29.5V4.526A4.5,4.5,0,0,0,24.961,0H14.783a4.5,4.5,0,0,0-4.539,4.528V5.959H4.376a4.379,4.379,0,0,0-1.123,8.61ZM30.716,46.65H9.029a3.63,3.63,0,0,1-3.484-3.84V14.67H34.2V42.81A3.63,3.63,0,0,1,30.716,46.65ZM12.537,4.526a2.208,2.208,0,0,1,2.247-2.235H24.961a2.208,2.208,0,0,1,2.247,2.235V5.959H12.537ZM4.376,8.252H35.369a2.063,2.063,0,0,1,0,4.126H4.376a2.063,2.063,0,1,1,0-4.126Zm0,0" transform="translate(0.003 0.001)"/>
//                                                       <path id="Path_10809" data-name="Path 10809" d="M164.545,154.7a1.146,1.146,0,0,0-1.146,1.146v21.663a1.146,1.146,0,0,0,2.292,0V155.849A1.146,1.146,0,0,0,164.545,154.7Zm0,0" transform="translate(-144.67 -136.971)"/>
//                                                   </g>
//                                               </svg>
//
//                                           </td>
//                                       </tr>
//                                   ))}
//                                   </tbody>
//                               </table>
//                           </div>
//
//                           <div className="justify-content-end align-items-center  d-flex container mt-5 FooterHome">
//                               <button className="FooterBtnHome btn" onClick={() => {
//                                   this.props.history.push("/Setting")
//                               }}>Settings
//                               </button>
//                               <button className="FooterBtnHome1 btn" onClick={() => {
//                                   this.props.getCustomerName('');
//                                   this.props.getGenarateid('');
//                                   this.props.getGenarateLink('');
//                                   this.props.getSelectType('');
//                                   this.props.getInvoiceNumber('');
//                                   this.props.history.push("/Link")
//                               }}>Create a Link
//                               </button>
//                               <button className="FooterBtnHome btn" onClick={this.logout}>
//                                   Log Out
//                               </button>
//                           </div>
//                       </div>
//                   </div>
//               </div>
//             </LoadingOverlay>
//         );
//     }
//
//     /*TODO*/
//     deleteObj = (name,id) => {
//         confirmAlert({
//             title: 'Proof Reading',
//             message: `Are you sure to remove ? ${name}`,
//             buttons: [
//                 {
//                     label: 'Yes',
//                     className:'danger-btn',
//                     onClick: () => {
//                         this.props.deleteCustomer(id);
//                         // this.handleNewRequest(Data, 'Pending');
//                         // this.handleSendEmail(CustomerName, 'New Link', 'https://academiahub.co', CustomerInvoiceNumber);
//                     }
//                 },
//                 {
//                     label: 'No',
//                     onClick: () => {
//                     }
//                 }
//             ]
//         });
//     };
// }
//
// const mapStateToProps = state => {
//     return {
//         imgUrl: state.signUp.imgUrl,
//         customer: state.homeReducer.customer,
//         searchTxt: state.homeReducer.searchTxt,
//         templates: state.homeReducer.templates,
//     }
// };
//
// export default connect(
//     mapStateToProps,
//     {
//         changeImageUrl,
//         getAllCustomers,
//         updateUserTableData,
//         searchCustomerUsingName,
//         getSearchTxt,
//         updateUserTableAmendmentData,
//         getCustomerName,
//         getGenarateid,
//         getGenarateLink,
//         getSelectType,
//         getInvoiceNumber,
//         GetTemplate,
//         sendClientData,
//         LogOutAdmin,
//         getSelectedMapTid,
//         getSelectedMapUid,
//         deleteCustomer
//     },
// )(Home);
import logo from '../assests/logo.png';
import React, {Component} from 'react'
import '../style/home.css';
import logo1 from '../assests/logo_black_orange.png';
import Yes from '../assests/Yes.png';
import Close from '../assests/No.png';
import downlode from '../assests/downlode.png';
import {connect} from "react-redux";
import {Avatar} from "@material-ui/core";
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/PropagateLoader'
import Table from 'react-bootstrap/Table'
import {
    changeImageUrl
} from '../actions/SignUpActions';
import {
    getAllCustomers,
    updateUserTableData,
    searchCustomerUsingName,
    getSearchTxt,
    updateUserTableAmendmentData,
    GetTemplate,
    sendClientData,
    LogOutAdmin,
    deleteCustomer,
} from '../actions/HomeAction';
import {
    getCustomerName,
    getGenarateid,
    getGenarateLink,
    getSelectType,
    getInvoiceNumber,
} from '../actions/LinkAction';
import {
    getSelectedMapTid,
    getSelectedMapUid,
} from '../actions/PdfDownloadAction';
import Resources from '../Resource/resources';
import {PDFDownloadLink} from "@react-pdf/renderer";
import MyDoc from "./Template";
import ReactNotification, {store} from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';
import moment from 'moment';
import 'animate.css';
import {confirmAlert} from "react-confirm-alert";

class Home extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpDate = this.handleUpDate.bind(this);
        this.handleSearchCustomers = this.handleSearchCustomers.bind(this);
        this.handleSearchTxt = this.handleSearchTxt.bind(this);
        this.handleAmendment = this.handleAmendment.bind(this);
        this.copyVal = this.copyVal.bind(this);
        this.downloadPDF = this.downloadPDF.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#6F7E84"
        this.props.getAllCustomers();
        const user = localStorage.getItem("UID");
        // console.log(user);
        const userVal = localStorage.getItem("userData");
        if (userVal !== null) {
            //console.log(JSON.parse(userVal));
            const convertData = JSON.parse(userVal);
            const img = convertData.image;
            this.props.changeImageUrl(img);
            //console.log(img);
        }
        Resources.Id = '';
        //console.log(JSON.parse(userVal));

        if (user === null) {
            this.props.history.push("/Login");
        }
        setTimeout(() => {
            this.setState({
                indicator: false
            })
        }, 3000);
    }

    state = {
        value: "",
        indicator: true,
    };

    handleChange(event) {
        this.setState({value: event.target.value})
    }

    handleUpDate(id, val) {
        this.props.updateUserTableData(id, val);
        if (val !== 'reject') {
            this.props.history.push("/Link");
        }
    }

    handleAmendment(id, val) {
        this.props.updateUserTableAmendmentData(id, val)
    }

    handleSearchCustomers() {
        this.props.searchCustomerUsingName(this.props.searchTxt, this.props.customer);
    }

    handleSearchTxt(event) {
        this.props.getSearchTxt(event.target.value);
    }

    copyVal(text) {
        // alert('Link copied to clipboard');
        navigator.clipboard.writeText(text).then(function () {
            store.addNotification({
                title: "Proof Reading",
                message: "Link copied to clipboard",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1500,
                    onScreen: true
                }
            });
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    downloadPDF(id, title, author, affilication) {
        this.props.GetTemplate(id, this.props);
        this.props.sendClientData(
            title,
            author,
            affilication
        );
    }

    logout() {
        this.props.LogOutAdmin(this.props);
    }


    render() {
        return (
            <LoadingOverlay active={this.state.indicator}
                            spinner={<BounceLoader color="#FFA300" size={20}/>}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <ReactNotification/>
                            <div className="header-Container container">
                                <div className="LogoView">
                                    <img src={logo1} alt={"Logo"} className="HeaderlogoHome"/>
                                </div>
                                <div>
                                    {this.props.imgUrl === '' ? (
                                        <Avatar
                                            src={logo}
                                            alt="Logo"
                                            id="homeLogo"
                                            className="userProfileLogo"
                                            onClick={() => {
                                                this.props.history.push("/SignUp")
                                                //this.props.history.push("/PdfScreen/CKiE202pwDnqFngBZ8uc/Vr2A0nIpXxP9Cm5h8wKb")
                                            }}
                                        />
                                    ) : (
                                        <Avatar
                                            src={this.props.imgUrl}
                                            alt="Logo"
                                            id="homeLogo"
                                            className="userProfileLogo"
                                            onClick={() => {
                                                // this.props.history.push("/PdfScreen/0HWRESamV9ZvLfbUJpcb/Vr2A0nIpXxP9Cm5h8wKb")
                                                //this.props.history.push("/PdfScreen/CKiE202pwDnqFngBZ8uc/Vr2A0nIpXxP9Cm5h8wKb")
                                                this.props.history.push("/SignUp")
                                            }}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="subView container align-items-center mb-3">
                                <h1 className="headerTextHome container">CLIENT LIST</h1>
                                <div className="flex-row d-flex search_div">
                                    <input type="link" name="name" className="form-control searchTxt"
                                           id="searchTxtInput" placeholder="Search" onChange={this.handleSearchTxt}/>
                                    <div className="searchBtnHome btn">Search
                                    </div>
                                </div>
                            </div>

                            <div className="container tableView  overflow-auto table-responsive">
                                <table className="table table-borderless tableStyle overflow-auto">
                                    <thead>
                                    <tr>
                                        <th className=" tblHeadTxt">Name</th>
                                        <th className="tblHeadTxt">Email</th>
                                        <th className="tblHeadTxt">Link</th>
                                        <th className="tblHeadTxt">Date</th>
                                        <th className="tblHeadTxt"></th>
                                        <th className="tblHeadTxt"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.customer.map((item, i) => (
                                        <tr id="rowStyle">
                                            <td className="tdStyle">
                                                <div className="tablefirsttab">
                                                    {item.name}
                                                </div>
                                            </td>
                                            {/*style={{width: 400}}*/}
                                            {/*style={{width: 200}}*/}
                                            <td className="tblValTxt">{item.invoiceNumber}</td>
                                            <td className="tblValTxt"
                                                title={item.link} id="link-td" onClick={() => {
                                                this.copyVal(item.link)
                                            }}>
                                                {item.link.slice(0, 20)}...
                                            </td>
                                            <td className="tblValTxt">
                                                {moment(
                                                    item.createAt,
                                                ).format('DD-MM-YYYY')}
                                            </td>
                                            <td className="align-items-center justify-content-center">
                                                <div className="flex-row  justify-content-end d-flex align-items-center">
                                                    <div className="tblRowbtnView ">
                                                        {item.request === "Pending" ? (
                                                            <button className="rowBtnHome" disabled={true}>New Link
                                                            </button>
                                                        ) : (
                                                            undefined
                                                        )}
                                                        {item.amendment === "Pending" ? (
                                                            <button className="rowBtnHome" disabled={true}>Amendment
                                                            </button>
                                                        ) : (
                                                            undefined
                                                        )}
                                                        {item.request === "Pending" ? (
                                                            <div className="rowBtnHome1 row justify-content-around">
                                                                <img src={Yes} alt={"Logo"} className="okBtn" onClick={() => {
                                                                    this.handleUpDate(item.userId, 'accept');
                                                                    Resources.Condition = false;
                                                                    Resources.Id = item.userId;
                                                                }}/>
                                                                <img src={Close} alt={"Logo"} className="okBtn" onClick={() => {
                                                                    this.handleUpDate(item.userId, 'reject');
                                                                }}/>
                                                            </div>
                                                        ) : (
                                                            undefined
                                                        )}
                                                        {item.amendment === "Pending" ? (
                                                            <div className="rowBtnHome1 row justify-content-around">
                                                                <img src={Yes} alt={"Logo"} className="okBtn" onClick={() => {
                                                                    this.handleAmendment(item.userId, 'accept');
                                                                }}/>
                                                                <img src={Close} alt={"Logo"} className="okBtn" onClick={() => {
                                                                    this.handleAmendment(item.userId, 'reject');
                                                                }}/>
                                                            </div>
                                                        ) : (
                                                            undefined
                                                        )}
                                                    </div>
                                                    {item.title !== undefined || '' ? (
                                                        <img src={downlode} alt={"Logo"} className="okBtnDownlode"
                                                             onClick={() => {
                                                                 this.props.getSelectedMapTid(item.type)
                                                                 this.props.getSelectedMapUid(item.userId)
                                                                 this.props.history.push("/pdfDownload");
                                                                 // this.downloadPDF(item.type, item.title, item.author, item.affiliation)
                                                             }}/>
                                                    ) : (
                                                        undefined
                                                    )}
                                                </div>
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.751" height="20.943" viewBox="0 0 39.751 48.943"
                                                     className="deleteBtn"
                                                     onClick={() => {
                                                         this.deleteObj(item.name, item.userId);
                                                     }}
                                                >
                                                    <g id="Group_10698" data-name="Group 10698" transform="translate(0 0)">
                                                        <path id="Path_10806" data-name="Path 10806" d="M223.545,154.7a1.146,1.146,0,0,0-1.146,1.146v21.663a1.146,1.146,0,0,0,2.292,0V155.849A1.146,1.146,0,0,0,223.545,154.7Zm0,0" transform="translate(-196.907 -136.971)"/>
                                                        <path id="Path_10807" data-name="Path 10807" d="M105.545,154.7a1.146,1.146,0,0,0-1.146,1.146v21.663a1.146,1.146,0,0,0,2.292,0V155.849A1.146,1.146,0,0,0,105.545,154.7Zm0,0" transform="translate(-92.432 -136.971)"/>
                                                        <path id="Path_10808" data-name="Path 10808" d="M3.252,14.57V42.81a6.324,6.324,0,0,0,1.681,4.361,5.645,5.645,0,0,0,4.1,1.771H30.716a5.643,5.643,0,0,0,4.1-1.771,6.324,6.324,0,0,0,1.681-4.361V14.57a4.379,4.379,0,0,0-1.123-8.61H29.5V4.526A4.5,4.5,0,0,0,24.961,0H14.783a4.5,4.5,0,0,0-4.539,4.528V5.959H4.376a4.379,4.379,0,0,0-1.123,8.61ZM30.716,46.65H9.029a3.63,3.63,0,0,1-3.484-3.84V14.67H34.2V42.81A3.63,3.63,0,0,1,30.716,46.65ZM12.537,4.526a2.208,2.208,0,0,1,2.247-2.235H24.961a2.208,2.208,0,0,1,2.247,2.235V5.959H12.537ZM4.376,8.252H35.369a2.063,2.063,0,0,1,0,4.126H4.376a2.063,2.063,0,1,1,0-4.126Zm0,0" transform="translate(0.003 0.001)"/>
                                                        <path id="Path_10809" data-name="Path 10809" d="M164.545,154.7a1.146,1.146,0,0,0-1.146,1.146v21.663a1.146,1.146,0,0,0,2.292,0V155.849A1.146,1.146,0,0,0,164.545,154.7Zm0,0" transform="translate(-144.67 -136.971)"/>
                                                    </g>
                                                </svg>

                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="justify-content-end align-items-center  d-flex container mt-5 FooterHome">
                                <button className="FooterBtnHome btn" onClick={() => {
                                    this.props.history.push("/Setting")
                                }}>Settings
                                </button>
                                <button className="FooterBtnHome1 btn" onClick={() => {
                                    this.props.getCustomerName('');
                                    this.props.getGenarateid('');
                                    this.props.getGenarateLink('');
                                    this.props.getSelectType('');
                                    this.props.getInvoiceNumber('');
                                    this.props.history.push("/Link")
                                }}>Create a Link
                                </button>
                                <button className="FooterBtnHome btn" onClick={this.logout}>
                                    Log Out
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }

    /*TODO*/
    deleteObj = (name,id) => {
        confirmAlert({
            title: 'Proof Reading',
            message: `Are you sure to remove ? ${name}`,
            buttons: [
                {
                    label: 'Yes',
                    className:'danger-btn',
                    onClick: () => {
                        this.props.deleteCustomer(id);
                        // this.handleNewRequest(Data, 'Pending');
                        // this.handleSendEmail(CustomerName, 'New Link', 'https://academiahub.co', CustomerInvoiceNumber);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        });
    };
}

const mapStateToProps = state => {
    return {
        imgUrl: state.signUp.imgUrl,
        customer: state.homeReducer.customer,
        searchTxt: state.homeReducer.searchTxt,
        templates: state.homeReducer.templates,
    }
};

export default connect(
    mapStateToProps,
    {
        changeImageUrl,
        getAllCustomers,
        updateUserTableData,
        searchCustomerUsingName,
        getSearchTxt,
        updateUserTableAmendmentData,
        getCustomerName,
        getGenarateid,
        getGenarateLink,
        getSelectType,
        getInvoiceNumber,
        GetTemplate,
        sendClientData,
        LogOutAdmin,
        getSelectedMapTid,
        getSelectedMapUid,
        deleteCustomer
    },
)(Home);
