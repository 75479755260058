import React from "react";
import Login from '../src/screen/Login'
import SignUp from '../src/screen/SignUp'
import Home from "./screen/Home";
import Link from "./screen/Link";
import Client from "./screen/ClientHome";
import Setting from "./screen/Setting";
import Downlode from "./screen/Download";
import Template from "./screen/Template";
import Document from "./screen/Document";
import LandingScreen from "./screen/LandingScreen";
import PdfDownload from "./screen/PdfDownload";
import PdfScreen from "./screen/PdfScreen";
import './App.css';
import {Switch, Route, Redirect, BrowserRouter,withRouter} from "react-router-dom"
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

function App() {
    return (
        <BrowserRouter>
            <ReactNotification/>
            <Switch>
                <Route exact path={"/"} component={withRouter(Home)}/>
                <Route exact path={"/SignUp"} component={withRouter(SignUp)}/>
                <Route exact path={"/Login"} component={withRouter(Login)}/>
                <Route exact path={"/Link"} component={withRouter(Link)}/>
                <Route exact path={"/Client"} component={withRouter(Client)}/>
                <Route exact path={"/Setting"} component={withRouter(Setting)}/>
                <Route exact path={"/downlode"} component={withRouter(Downlode)}/>
                <Route exact path={"/Template"} component={withRouter(Template)}/>
                <Route exact path={"/document"} component={withRouter(Document)}/>
                <Route exact path={"/PdfScreen/:uid/:tid"} component={withRouter(PdfScreen)}/>
                <Route exact path={"/pdfDownload"} component={withRouter(PdfDownload)}/>
                <Route exact path={"/landing/:uid/:tid"} component={withRouter(LandingScreen)}/>
                <Redirect to={"/"}/>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
