const INITIAL_STATE = {
    Tid: '',
    Uid: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_SELECTED_MAP_TID':
            // console.log(action.payload);
            return { ...state, Tid: action.payload };
        case 'GET_SELECTED_MAP_UID':
            // console.log(action.payload);
            return { ...state, Uid: action.payload };
        default:
            return state;
    }
};
