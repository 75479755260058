import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import App from "./App";


import {Provider} from "react-redux";
import store from "./app/store"
import {BrowserRouter} from "react-router-dom";
import Login from "./screen/Login";

ReactDOM.render(
    <React.Fragment>

        <Provider store={store}>
            <App/>
        </Provider>

    </React.Fragment>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
