const INITIAL_STATE = {
    sampleType: '',
    customerName: '',
    invoiceNumber: '',
    genarateLink:'',
    genareteUid: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_SELECT_SAMPLE_TYPE':
            return { ...state, sampleType: action.payload };
        case 'GET_CUSTOMER_NAME':
            return {...state, customerName: action.payload};
        case 'GET_INVOICE_NUMBER':
            return {...state, invoiceNumber: action.payload};
        case 'GET_GENARATE_LINK':
            return {...state, genarateLink: action.payload};
        case 'GENARATE_USER_ID':
            return {...state, genareteUid: action.payload};
        default:
            return state;
    }
};
