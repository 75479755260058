export const login = () => async dispatch => {
    // console.log("calling");
    dispatch({
        type: 'LOGIN_DATA',
        payload: 'log',
    });
};

export const getEmail = (text) => async dispatch => {
    // console.log("calling");
    dispatch({
        type: 'GET_EMAIL',
        payload: text,
    });
};

export const getPassword = (text) => async dispatch => {
    // console.log("calling");
    dispatch({
        type: 'GET_PASSWORD',
        payload: text,
    });
};

export const getLoginUserDetail = (array) => async dispatch =>{
    // console.log('working');
    dispatch({
        type: 'GET_LOGIN_USER_DATA',
        payload: array,
    });
    dispatch({
        type: 'GET_AND_SET_FIRST_NAME',
        payload: array.firstname,
    });
    dispatch({
        type: 'GET_AND_SET_LAST_NAME',
        payload: array.lastname,
    });
    dispatch({
        type: 'GET_AND_SET_EMAIL_ADDRESS',
        payload: array.email,
    });
    dispatch({
        type: 'GET_AND_SET_CONTACT_NUMBER',
        payload: array.contactnumber,
    });
    dispatch({
        type: 'CHANGE_IMAGE_URL',
        payload: array.image,
    });
    dispatch({
        type: 'CHANGE_PASSWORD',
        payload: array.password,
    });
};
