import logo from '../assests/logo.png';
import React, {Component} from 'react'
import '../style/link.css';
import {Avatar} from '@material-ui/core'
import ReactCrop from "react-image-crop";
import {connect} from "react-redux";
import {changeImageUrl} from "../actions/SignUpActions";
import {
    getSelectType,
    getCustomerName,
    getGenarateLink,
    getInvoiceNumber,
    saveCustomer,
    getGenarateid,
    updateDownloadUrl,
} from "../actions/LinkAction";
import {db} from "../Firebase";
import Resource from '../Resource/resources';
import firebase from 'firebase'
import sha256 from "crypto-js/md5";
import logo1 from "../assests/copy.png";
import ReactNotification, {store} from "react-notifications-component";
import Container from "@material-ui/core/Container";

class Link extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleGetSampleType = this.handleGetSampleType.bind(this);
        this.handleGetCustomerName = this.handleGetCustomerName.bind(this);
        this.handleGetInvoiceNumber = this.handleGetInvoiceNumber.bind(this);
        this.handleGetGenarateLink = this.handleGetGenarateLink.bind(this);
        this.handleSubmitData = this.handleSubmitData.bind(this);
        this.handleUpdateLink = this.handleUpdateLink.bind(this);
        this.copyVal = this.copyVal.bind(this);
    }

    state = {
        value: "",
        src: null,
        crop: {
            unit: '%',
            aspect: 16 / 9,
            x: 10,
            y: 10,
            width: 50,
            height: 50
        },
        croppedImageUrl: null,
        cropComplete: {},
        count: 0
    };

    handleGetSampleType(data) {
        // console.log(data);
        this.props.getSelectType(data);
    }

    handleGetCustomerName(event) {
        // console.log(event.target.value);
        this.props.getCustomerName(event.target.value);
    }

    handleGetInvoiceNumber(event) {
        // console.log(event.target.value);
        this.props.getInvoiceNumber(event.target.value);
    }

    handleGetGenarateLink(event) {
        // console.log(event.target.value);
        this.props.getGenarateLink(event.target.value);
    }

    handleSubmitData() {
        if (this.props.genarateLink === ''){
            store.addNotification({
                title: "Proof Reading",
                message: "Something went wrong...",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1500,
                    onScreen: true
                }
            });
        }else {
            this.props.saveCustomer(
                this.props.sampleType,
                this.props.customerName,
                this.props.invoiceNumber,
                this.props.genarateLink,
                this.props.genareteUid,
                new Date().toISOString(),
                this.props,
            )
        }
    }

    handleUpdateLink(id, link) {
        this.props.updateDownloadUrl(id, link, this.props);
    }

    handleChange(event) {
        this.setState({value: event.target.value})
    }

    componentDidMount() {
        // console.log(new Date().toISOString());
        // console.log(this.props);
        // console.log(Resource.Id);
        if (Resource.Id === ''){
            setTimeout(() => {
                const id = db.collection('customer').doc().id;
                this.props.getGenarateid(id);
                // console.log(id);
            }, 1000);
        }else {
            this.props.getGenarateid(Resource.Id);
            // console.log(Resource.Id);
        }
        const userVal = localStorage.getItem("userData");
        if (userVal !== null) {
            // console.log(JSON.parse(userVal));
            const convertData = JSON.parse(userVal);
            const img = convertData.image;
            this.props.changeImageUrl(img);
            // console.log(img);
        }
    }



    copyVal() {
        var copyText = document.getElementById("link")
        copyText.select()
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy")

        store.addNotification({
            title: "Proof Reading",
            message: "Link copied",
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 1500,
                onScreen: true
            }
        });
     }

    render() {
        return (
            <div className="d-flex align-items-center justify-content-center main">
                <ReactNotification/>
                <p className="position-absolute m-auto backIconTxt" id="backPara" onClick={() => {
                    this.props.history.push("/")
                }}>Back</p>
                {this.props.imgUrl === '' ? (
                    <Avatar
                        src={this.state.src === null ? this.state.croppedImageUrl === null ? logo : this.state.croppedImageUrl : this.state.croppedImageUrl}
                        alt="Logo"
                        className="position-absolute m-auto font-weight-bold"
                        id="ClosePara"
                        style={{height: '50px', width: '50px'}}
                    />
                ) : (
                    <Avatar
                        src={this.props.imgUrl}
                        alt="Logo"
                        className="position-absolute m-auto font-weight-bold"
                        id="ClosePara"
                        style={{height: '50px', width: '50px'}}
                    />
                )}

                <div className="container mainGenerator">
                    <div className="row align-items-center justify-content-around">
                        <div className="container mainCop">
                            <label className="text-center"> CoP Generator</label>
                        </div>
                        <button className="btn sampleBtn"
                                style={{'background-color': this.state.count === 1 ? '#FCCF7F' : 'orange'}} id="sbtn"
                                onClick={() => {
                                    this.setState({count: 1});
                                    this.handleGetSampleType('Vr2A0nIpXxP9Cm5h8wKb');
                                    // WYEGV5MOqtJwC1P6oI0i
                                    // this.handleGetSampleType('PLdV3tjspq1AW7jaEJUn');
                                }}>CoP Type 1
                        </button>
                        <button className="btn sampleBtn"
                                style={{'background-color': this.state.count === 2 ? '#FCCF7F' : 'orange'}} id="sbtn1"
                                onClick={() => {
                                    this.setState({count: 2});
                                    this.handleGetSampleType('WYEGV5MOqtJwC1P6oI0i');
                                    // YI0GPtLyxiEChWoP0H7B
                                    // this.handleGetSampleType('QDZxGoHRnLS7SMKNiZOW');
                                }}>CoP Type 2
                        </button>
                        <button className="btn sampleBtn"
                                style={{'background-color': this.state.count === 3 ? '#FCCF7F' : 'orange'}} id="sbtn2"
                                onClick={() => {
                                    this.setState({count: 3});
                                    this.handleGetSampleType('YI0GPtLyxiEChWoP0H7B');
                                    // Vr2A0nIpXxP9Cm5h8wKb
                                    // this.handleGetSampleType('QYsHupXzdixB0B6s3lAq');
                                }}>CoP Type 3
                        </button>

                    </div>


                    <div className="mt-5">
                        <div className="form-group align-items-center justify-content-center">
                            <label htmlFor="fname" className="pl-3 labeltxt">Customer Name</label>
                            <div className="align-items-center justify-content-between setWidth">
                                <input type="text" id="cname" className="form-control inputsLink"
                                       onChange={this.handleGetCustomerName} value={this.props.customerName} />
                            </div>
                        </div>

                        <div className="form-group align-items-center justify-content-center">
                            <label htmlFor="fname" className="pl-3 labeltxt">Email Address</label>
                            <div className="align-items-center justify-content-between setWidth">
                                <input type="text" id="invoice" className="form-control inputsLink"
                                       onChange={this.handleGetInvoiceNumber} value={this.props.invoiceNumber} />
                            </div>
                        </div>

                        <div className="form-group align-items-center justify-content-center ">
                            <label htmlFor="fname" className="pl-3 labeltxt">Link</label>
                            <div className='flex-row d-flex justify-content-between align-items-center'>
                                <div
                                    className="align-items-center justify-content-between setWidthLink">
                                    <input type="text" id="link" className="form-control inputs1Link"
                                           value={this.props.genarateLink} readOnly/>
                                </div>
                                <button className="genarateBtn btn" onClick={() => {
                                    if (this.props.sampleType === ''){
                                        store.addNotification({
                                            title: "Proof Reading",
                                            message: "Select Sample Type",
                                            type: "warning",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animate__animated", "animate__fadeIn"],
                                            animationOut: ["animate__animated", "animate__fadeOut"],
                                            dismiss: {
                                                duration: 1500,
                                                onScreen: true
                                            }
                                        });
                                    }else {
                                        // this.props.getGenarateLink("https://certificates.proofreadingbyphd.com/landing/" + this.props.genareteUid + "/" + this.props.sampleType);
                                        this.props.getGenarateLink("https://academiahub.co/landing/" + this.props.genareteUid + "/" + this.props.sampleType);
                                         //this.props.getGenarateLink("https://proof.reading.neominds.lk/landing/" + this.props.genareteUid + "/" + this.props.sampleType);
                                        // this.props.getGenarateLink("https://neominds.lk:4050/landing/" + this.props.genareteUid + "/" + this.props.sampleType);
                                          //this.props.getGenarateLink("http://localhost:3000/landing/" + this.props.genareteUid + "/" + this.props.sampleType);
                                    }
                                }}>Generate
                                </button>
                                <img src={logo1} alt={"Logo"} className="copyLink" onClick={this.copyVal}/>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.src !== null ? <div
                        className="position-absolute w-50 h-100 flex-column justify-content-center align-items-center d-flex">

                        <ReactCrop
                            src={this.state.src}
                            crop={this.state.crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />

                        <button className="btn FooterBtn position-relative mt-5" onClick={this.onFinsih}>Crop</button>
                    </div> : undefined
                }

                {Resource.Condition === false ? (
                    <div className="Footer">
                        <button className="FooterLinkBtn btn" onClick={() => {
                            this.handleUpdateLink(Resource.Id, this.props.genarateLink);
                            Resource.Condition = true;
                            Resource.Id = "";
                        }}>Submit
                        </button>
                    </div>
                ) : (
                    <div className="footerLink">
                        <button className="FooterLinkBtn btn" onClick={this.handleSubmitData}>Done</button>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        imgUrl: state.signUp.imgUrl,
        sampleType: state.linkReducer.sampleType,
        customerName: state.linkReducer.customerName,
        invoiceNumber: state.linkReducer.invoiceNumber,
        genarateLink: state.linkReducer.genarateLink,
        genareteUid: state.linkReducer.genareteUid,
    }
};

export default connect(
    mapStateToProps,
    {
        changeImageUrl,
        getSelectType,
        getCustomerName,
        getGenarateLink,
        getInvoiceNumber,
        saveCustomer,
        getGenarateid,
        updateDownloadUrl,
    },
)(Link);
