import React, {Component} from 'react';
import '../../style/document.css';
import ReactCrop from "react-image-crop";
import {Modal} from "@material-ui/core";
import close from "../../assests/close.png";
import {connect} from "react-redux";
import {fireStorage} from '../../Firebase';

import {
    getCompanyLogoImage,
    getCompanySeal,
    getSignatureImage,
    getLogoTwo,
} from '../../actions/DocumentAction';

class picker extends Component {

    constructor(props) {
        super(props);
        this.handleChangeSignatureImage = this.handleChangeSignatureImage.bind(this);
        this.handleChangeCompanySealImage = this.handleChangeCompanySealImage.bind(this);
        this.handleChangeLogoSecond = this.handleChangeLogoSecond.bind(this);
        this.handleChangeCompanyLogo = this.handleChangeCompanyLogo.bind(this);
    }

    state = {
        value: "",
        src: null,
        crop: {
            unit: 'px', // default, can be 'px' or '%'
            x: 130,
            y: 50,
            width: 150,
            height: 150,
        },
        croppedImageUrl: null,
        croppedImageUrl2: null,
        cropComplete: {},
    };

    componentWillMount() {
        let crop = this.state.crop;
        crop.height = this.props.height;
        crop.width = this.props.width;
        this.setState({crop: crop});
    }

    onSelectFile1 = e => {
        // console.log('calling');
        if (e.target.files && e.target.files.length > 0) {
            const reader1 = new FileReader();
            reader1.addEventListener(
                'load',
                () => {
                    this.setState({
                        src: reader1.result,
                    })

                },
                false
            );
            reader1.readAsDataURL(e.target.files[0]);
            // console.log(e.target.files[0]);
        }
    };

    onImageLoaded = image => {
        // console.log('onCropComplete', image)
        this.imageRef = image;
        // console.log(image);
    };

    onCropComplete = crop => {
        // console.log('onCropComplete', crop);
        this.setState({cropComplete: crop})

    };

    onFinsih = () => {
        // console.log(this.props.idd);
        this.makeClientCrop(this.state.cropComplete);
    };


    onCropChange = crop => {
        this.setState({crop})
    };

    imageUploadFireStore(url, type) {
        const img1 = url;
        const fileName = img1.name;
        // console.log(fileName);
        const uploadTask = fireStorage.ref(`/images/${fileName}`).put(img1);
        //initiates the firebase side uploading
        uploadTask.on('state_changed',
            (snapShot) => {
                // console.log(snapShot)
            }, (err) => {
                // console.log(err)
            }, () => {
                fireStorage.ref('images').child(fileName).getDownloadURL()
                    .then(fireBaseUrl => {
                        // console.log(fireBaseUrl);
                        if (type === 'lb') {
                            this.handleChangeCompanyLogo(fireBaseUrl);
                        } else if (type === 'lb1') {
                            this.handleChangeLogoSecond(fireBaseUrl);
                        } else if (type === 'lb2') {
                            this.handleChangeCompanySealImage(fireBaseUrl);
                        } else if (type === 'lb3') {
                            this.handleChangeSignatureImage(fireBaseUrl);
                        }
                    })
            })
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const fName = `${Date.now()}img.png`;
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                fName
            );
            this.setState({croppedImageUrl});
            this.setState({src: null});
            if (this.props.idd === 'lb') {
                this.imageUploadFireStore(croppedImageUrl, 'lb')
            } else if (this.props.idd === 'lb1') {
                this.imageUploadFireStore(croppedImageUrl, 'lb1')
            } else if (this.props.idd === 'lb2') {
                this.imageUploadFireStore(croppedImageUrl, 'lb2')
            } else if (this.props.idd === 'lb3') {
                this.imageUploadFireStore(croppedImageUrl, 'lb3')
            }
        }
    }

    getCroppedImg(image, crop, fileName) {
        // console.log(fileName);
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                // console.log(new File([blob], fileName, {type: 'image/png'}));
                this.fileUrl = new File([blob], fileName, {type: 'image/png'});
                resolve(this.fileUrl);
                // console.log(this.fileUrl);
            }, 'image/png');
            this.setState({croppedImageUrl2: canvas.toDataURL('image/png')});
        });
    }


    handleChangeCompanyLogo(data) {
        this.props.getCompanyLogoImage(data);
    }

    handleChangeSignatureImage(data) {
        this.props.getSignatureImage(data);
    }

    handleChangeCompanySealImage(data) {
        this.props.getCompanySeal(data);
    }

    handleChangeLogoSecond(data) {
        this.props.getLogoTwo(data);
    }

    render() {
        // console.log(this.props.Cimages);
        return (
            <div className="text-center">
                <div className="justify-content-center align-items-center d-flex" style={{
                    width: this.props.width,
                    height: this.props.height,
                    'border-radius': 10,
                    border: '1px solid lightgray'
                }}>
                    {

                        this.props.Cimages !== null ? <img src={this.props.Cimages} alt="Logo1" style={{
                            width: this.props.width,
                            height: this.props.height,
                            'border-radius': 10,
                            border: '1px solid lightgray'
                        }}
                        /> : this.state.croppedImageUrl === null ?
                            <p className="companyLogo2Txt">{this.props.palceHolder}</p> :
                            <img src={this.state.croppedImageUrl2} alt="Logo1" style={{
                                width: this.props.width,
                                height: this.props.height,
                                'border-radius': 10,
                                border: '1px solid lightgray'
                            }}
                            />
                    }


                </div>
                <input id={this.props.idd} type="file" onChange={(e) => {
                    this.onSelectFile1(e);
                }} className="file-input1"/>
                <label htmlFor={this.props.idd}>
                    <div className="btn1D btn mt-1">choose a file</div>
                </label>


                <Modal
                    open={this.state.src !== null}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none'}}
                >
                    <div className="container position-absolute h-75">
                        <div
                            className=" h-100 flex-column justify-content-center align-items-center d-flex ">

                            <ReactCrop
                                src={this.state.src}
                                crop={this.state.crop}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                locked={true}
                            />

                            <button className="btn FooterBtnSignUp position-relative mt-5"
                                    onClick={this.onFinsih}>Crop
                            </button>
                        </div>
                    </div>

                </Modal>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        companylogo: state.documentReducer.companylogo,
        signature_img: state.documentReducer.signature_img,
        company_seal: state.documentReducer.company_seal,
        logo_two: state.documentReducer.logo_two,
    }
};

export default connect(
    mapStateToProps,
    {
        getCompanyLogoImage,
        getCompanySeal,
        getLogoTwo,
        getSignatureImage,
    },
)(picker);

