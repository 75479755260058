import React, {Component} from 'react'
import '../style/pdfDownload.css';
import {connect} from "react-redux";
import {
    getUserData,
    getTemplateData,
    getLogClientID,
    getUserDataToPDF,
} from "../actions/ClientHomeAction"
import logo from '../assests/logo_black_orange.png';
import {PDFDownloadLink} from "@react-pdf/renderer";
import MyDoc from "./Template";
import Cloud from "../assests/cloud.png";


class PdfDownload extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
         console.log(this.props.Tid);
         console.log(this.props.Uid);
        this.props.getTemplateData(this.props.Tid);
        this.props.getUserDataToPDF(this.props.Uid);
    }

    render() {
        return (
            <div id="mainPdf">
                <img src={Cloud} className="CloudImg m-3 animated bounce"/>
                {/*<a href={`https://us-central1-proof-reading-e6b1f.cloudfunctions.net/readings/render?url=https://neominds.lk:4050/PdfScreen/${this.props.Uid}/${this.props.Tid}`} download className="btnBDownload">*/}
                {/*    Download*/}
                {/*</a>*/}
                {/*<a href={`https://us-central1-proof-reading-e6b1f.cloudfunctions.net/readings/render?url=https://proof.reading.neominds.lk/PdfScreen/${this.props.Uid}/${this.props.Tid}`} download className="btnBDownload">*/}
                {/*    Download*/}
                {/*</a>*/}
                <a href={`https://us-central1-proof-reading-2021.cloudfunctions.net/readings/render?url=https://academiahub.co/PdfScreen/${this.props.Uid}/${this.props.Tid}&name=CoP_${this.props.titleTxt}`} download className="btnBDownload" >
                    Download
                </a>
                {/*<a href={`http://localhost:4000/render?url=http://localhost:3000/PdfScreen/${this.props.Uid}/${this.props.Tid}`} download className="btnBDownload" onClick={() => {*/}
                {/*    //this.handleAmendmentRequest(this.props.clientID, 'finish')*/}
                {/*}}>*/}
                {/*    Download*/}
                {/*</a>*/}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        titleTxt: state.clientReducer.titleTxt,
        author: state.clientReducer.author,
        affiliation: state.clientReducer.affiliation,
        userVal: state.clientReducer.userVal,
        templateVal: state.clientReducer.templateVal,
        clientID: state.clientReducer.clientID,
        Tid: state.pdfDownload.Tid,
        Uid: state.pdfDownload.Uid,
    }
};

export default connect(
    mapStateToProps,
    {
        getUserData,
        getTemplateData,
        getLogClientID,
        getUserDataToPDF,
    },
)(PdfDownload);
