export const getSelectedMapTid = (text) => async dispatch => {
    dispatch({
        type: 'GET_SELECTED_MAP_TID',
        payload: text,
    });
};

export const getSelectedMapUid = (text) => async dispatch => {
    dispatch({
        type: 'GET_SELECTED_MAP_UID',
        payload: text,
    });
};
