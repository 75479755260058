import React, {Component} from 'react'
import '../style/landing.css';
import {connect} from "react-redux";
import {
    getUserData,
    getTemplateData,
    getLogClientID
} from "../actions/ClientHomeAction"
import logo from '../assests/logo_black_orange.png';


class LandingScreen extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // console.log(this.props.history.location);
        // console.log(this.props.match.params);
        localStorage.setItem('cuid', this.props.match.params.uid);
        localStorage.setItem('ctid', this.props.match.params.tid);
        // console.log(this.props.match.params.uid);
        setTimeout(()=>{
            this.props.getUserData(this.props.match.params.uid, this.props);
        },1500);
        this.props.getTemplateData(this.props.match.params.tid);
        this.props.getLogClientID(this.props.match.params.uid);
        // console.log(this.props.match.params);
        // console.log(this.props.match.params.tid);
        setTimeout(()=>{
            localStorage.setItem('ClientID',this.props.clientID);
            localStorage.setItem('TemplateID', this.props.match.params.tid);
            //console.log('clientID :' + this.props.clientID);
        },500);
    }

    render() {
        return (
            <div id="main">
                <div className="landingHead">
                    <text className="landingHeadTxt">
                        Let's Generate your Certificate!
                    </text>
                    <text className="landingHeadTxtSub">
                        Note :  Always Submit the<br/>Certificate to Journal or<br/>University
                    </text>
                </div>
                <img src={logo} className="landingLogo animated bounce" id="animated-example"/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        titleTxt: state.clientReducer.titleTxt,
        author: state.clientReducer.author,
        affiliation: state.clientReducer.affiliation,
        userVal: state.clientReducer.userVal,
        templateVal: state.clientReducer.templateVal,
        clientID: state.clientReducer.clientID,
        clientName: state.downloadReducer.clientName,
        clientInvoiceNumber: state.downloadReducer.clientInvoiceNumber,
    }
};

export default connect(
    mapStateToProps,
    {
        getUserData,
        getTemplateData,
        getLogClientID
    },
)(LandingScreen);
