import {auth, db, fireStorage} from '../Firebase';
import {store} from 'react-notifications-component';

export const getSelectType = (text) => async dispatch => {
    dispatch({
        type: 'GET_SELECT_SAMPLE_TYPE',
        payload: text,
    });
};

export const getCustomerName = (text) => async dispatch => {
    dispatch({
        type: 'GET_CUSTOMER_NAME',
        payload: text,
    });
};

export const getInvoiceNumber = (text) => async dispatch => {
    dispatch({
        type: 'GET_INVOICE_NUMBER',
        payload: text,
    });
};

export const getGenarateLink = (text) => async dispatch => {
    dispatch({
        type: 'GET_GENARATE_LINK',
        payload: text,
    });
};

export const getGenarateid = (text) => async dispatch => {
    // console.log(text);
    dispatch({
        type: 'GENARATE_USER_ID',
        payload: text,
    });
};

export const saveCustomer = (
    sampleType,
    customerName,
    invoiceNumber,
    genarateLink,
    genarateId,
    registerTime,
    props,
) => async dispatch =>{
    await db
        .collection('customer')
        .doc(genarateId)
        .set({
            name: customerName,
            type: sampleType,
            invoiceNumber: invoiceNumber,
            link: genarateLink,
            userId: genarateId,
            createAt: registerTime,
        })
        .then((res)=>{
            // console.log(res);
            if (res === undefined){
                store.addNotification({
                    title: "Proof Reading",
                    message: "User Added Successfully",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1500,
                        onScreen: true
                    }
                });
                setTimeout(()=>{
                    props.history.push("/");
                },1500);
            }
        })
        .catch((err)=>{
            console.log(err.code);
        })
};

export const updateDownloadUrl = (id, val, props) => async dispatch => {
    const data = {
        link: val
    };
    // console.log(id + '-----' + val);
    db
        .collection('customer').doc(id).update(data)
        .then((res) => {
            if (res === undefined){
                store.addNotification({
                    title: "Proof Reading",
                    message: "Update Successfully",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 1500,
                        onScreen: true
                    }
                });
                setTimeout(()=>{
                    props.history.push("/");
                },1500);
            }
        })
        .catch((error) => {
            console.log(error);
        })
};
