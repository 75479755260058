import React, {Component} from 'react'
import '../style/PDfScreenStyle.css';
import {connect} from "react-redux";
import {
    getUserData,
    getTemplateData,
    getLogClientID,
    getUserDataToPDF,
} from "../actions/ClientHomeAction"
import Template from "./Template";
import logo from "../assests/logo_black_orange.png";
import logo4 from "../assests/logo4.png";
import logo3 from "../assests/logo3.png";
import footer from "../assests/footer.png";
import {Text} from "@react-pdf/renderer";


class PdfScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: '',
        }
    }

    componentDidMount() {
        //console.log(new Date().toDateString());
        let d = new Date(Date.now());
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        let mo = new Intl.DateTimeFormat('en', {month: 'short'}).format(d);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
        let day = new Intl.DateTimeFormat('en', {weekday: 'short'}).format(d);
        this.setState({
            currentDate: `${da} ${mo} ${ye}, ${day}`
        });
        // this.setState({
        //     currentDate: new Date().toDateString()
        // });
        // console.log(this.props.match.params);
        this.props.getTemplateData(this.props.match.params.tid);
        this.props.getUserDataToPDF(this.props.match.params.uid);
    }

    render() {
        return (
            <div className="App">
                <div className="A4Body" id="A4BodyContent">
                    <div className="A4BodyHead">
                        <div className="A4HeadTxt">
                            <text className="A4HeadTxtFont1">
                                {this.props.templateVal.name}
                            </text>
                            <text className="A4HeadTxtFont">
                                {this.props.templateVal.post}
                            </text>
                            <text className="A4HeadTxtFont">
                                Company: {this.props.templateVal.company}
                            </text>
                            <text className="A4HeadTxtFont">
                                Registration: {this.props.templateVal.registorNumber}
                            </text>
                            <text className="A4HeadTxtFont">
                                Email: {this.props.templateVal.emailAddress}
                            </text>
                            <text className="A4HeadTxtFont">
                                Mobile: {this.props.templateVal.mobile}
                            </text>
                        </div>
                        <div className="A4HeadLogo">
                            <img alt={"Logo"} src={this.props.templateVal.companyLogo} className="A4HeadLogoImg"/>
                        </div>
                    </div>
                    <div className="A4BodyTitleView">
                        <text className="A4BodyTitle">
                            {this.props.templateVal.headLine}
                        </text>
                    </div>
                    <div className="A4BodyView">
                        <Text className="A4BodyViewTxt">
                            {this.props.templateVal.description}
                        </Text>
                    </div>
                    <div className="A4BodyViewSubTitle">
                        <text className="A4Title">
                            Title :
                        </text>
                        <text className="A4TitleSub">
                            {this.props.titleTxt}
                        </text>
                    </div>
                    <div className="A4BodyViewSub A4Margin">
                        <text className="A4Title">
                            Authors :
                        </text>
                        {this.props.author.map((item, i)=>(
                            <text className="A4TitleSub" dangerouslySetInnerHTML={{__html: item}}>
                            </text>
                        ))}
                    </div>
                    <div className="A4BodyViewSub5">
                        <text className="A4Title">
                            Affiliations :
                        </text>
                        {this.props.affiliation.map((item, i)=>(
                            <text className="A4TitleSub" dangerouslySetInnerHTML={{__html: item}}>
                                {/*{this.props.affiliation}*/}
                            </text>
                        ))}
                    </div>
                    <div className="A4BodyViewSubOne A4MarginTop">
                        <text className="A4TitleSub">
                            Date Issued: {this.props.templateVal.download_date}
                            {/*Date Issued: {this.state.currentDate}*/}
                        </text>
                    </div>
                    <div className="A4BodyViewSubOne A4MarginTop">
                        <text className="A4TitleSub">
                            Yours truly,
                        </text>
                    </div>
                    <div className="A4BodyViewSubTwo">
                        <img alt={"Logo"} src={this.props.templateVal.signatureImage} className="A4HBodyLogoImg"/>
                        <img alt={"Logo"} src={this.props.templateVal.companySeal} className="A4HBodyLogoImg2"/>
                        <div className="A4HBodyLogoImg3View">
                            <img alt={"Logo"} src={this.props.templateVal.logoTwo} className="A4HBodyLogoImg3"/>
                            <img alt={"Logo"} src={logo3} className="A4HBodyLogoImg4"/>
                        </div>
                    </div>
                    <div className="A4FooterView">
                        <div className="A4FooterViewOne">
                            <div className="A4FooterViewThree">
                                <text className="A4FooterTxtSub">
                                    {this.props.templateVal.footerDescriptionOne}
                                </text>
                                <text className="A4FooterTxt">
                                    Documents Proofread/Edited/Structured/Paraphrased
                                </text>
                            </div>
                            {/*------------------------------------------------------------------------*/}
                            <div className="A4FooterViewThree">
                                <text className="A4FooterTxtSub">
                                    {this.props.templateVal.footerDescriptionTwo}
                                </text>
                                <text className="A4FooterTxt">
                                    Articles Accepted in SSCI/SCI/HSCI/Scopus/ERA
                                </text>
                            </div>
                            {/*------------------------------------------------------------------------*/}
                            <div className="A4FooterViewThree">
                                <text className="A4FooterTxtSub">
                                    {this.props.templateVal.footerDescriptionThree}
                                </text>
                                <text className="A4FooterTxt">
                                    Theses Passed Viva
                                </text>
                            </div>
                            {/*------------------------------------------------------------------------*/}
                            <div className="A4FooterViewThree">
                                <text className="A4FooterTxtSub">
                                    {this.props.templateVal.footerDescriptionFore}
                                </text>
                                <text className="A4FooterTxt">
                                    Grant Documents Approved
                                </text>
                            </div>
                            {/*------------------------------------------------------------------------*/}
                            <div className="A4FooterViewThree">
                                <text className="A4FooterTxtSub">
                                    {this.props.templateVal.footerDescriptionFive}
                                </text>
                                <text className="A4FooterTxt">
                                    CV and Research Statements Completed
                                </text>
                            </div>
                            {/*------------------------------------------------------------------------*/}
                        </div>
                        <div className="A4FooterViewTwo">
                            <img alt={"Logo"} src={logo4} className="A4HBodyLogoImg5"/>
                        </div>
                    </div>
                    <div className="A4BodyViewSubOne">
                        <text className="A4FooterTxt">
                            Remaining documents are all under review
                        </text>
                    </div>
                    <div className="A4BodyViewSubOne">
                        <img alt="Logo" src={footer} className="A4footerLogo"/>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        titleTxt: state.clientReducer.titleTxt,
        author: state.clientReducer.author,
        affiliation: state.clientReducer.affiliation,
        userVal: state.clientReducer.userVal,
        templateVal: state.clientReducer.templateVal,
        clientID: state.clientReducer.clientID,
    }
};

export default connect(
    mapStateToProps,
    {
        getUserData,
        getTemplateData,
        getLogClientID,
        getUserDataToPDF
    },
)(PdfScreen)
