const INITIAL_STATE = {
    templateId: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_AND_SET_TEMPLATE_DATA':
            // console.log(action.payload);
            return { ...state, templateId: action.payload };
        default:
            return state;
    }
};
