import {combineReducers} from "redux";
import LoginReducer from "./loginReducer"
import SignUpReducer from "./SignUpReducer";
import LinkReducer from "./LinkReducer";
import HomeReducer from "./HomeReducer";
import ClientReducer from "./ClientReducer";
import DocumentReducer from "./DocumentReducer";
import SettingReducer from "./SettingReducer";
import PdfDownloadReducer from "./PdfDownloadReducer";
import DownloadReducer from "./DownloadReducer";

const RootReducer=combineReducers({
        Login:LoginReducer,
        signUp:SignUpReducer,
        linkReducer: LinkReducer,
        homeReducer: HomeReducer,
        clientReducer: ClientReducer,
        documentReducer: DocumentReducer,
        setting: SettingReducer,
        pdfDownload: PdfDownloadReducer,
        downloadReducer: DownloadReducer,
});


export default RootReducer;
